import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import '../public/styles/create.css'

import { Link } from 'react-router-dom';

//moment
import moment from "moment/min/moment-with-locales"

//antd
import {
    CalculatorOutlined,
    CloseOutlined,
    SlidersOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';

//REDUX
import { useDispatch, useSelector } from 'react-redux'

//ant_____________________
import {
    message,
    Input,
    Select,
    Form,
    TreeSelect,
    Steps,
    Divider,
    InputNumber
} from 'antd';

import {
    PlusCircleOutlined,

    FlagFilled
} from '@ant-design/icons';
import Button from './Button';
import AddItem from './AddItem';
import ScoreDots from './ScoreDots';
import ScoreDotsParametrable from './ScoreDotsParametrable';
import ScoreRapportDots from './ScoreRapportDots';
import ScoreSliderParametrable from './ScoreSliderParametrable'
import ScoreSliderRapportParametrable from './ScoreSliderRapportParametrable'
import { formatCodeNew, short, compareObjects, criticity, vulnerability, predictMaitriseCible, rapportGC, calculNet, calculNetSmooth, scoreTotal } from "../helpers"

moment.locale('fr')

//HELPERS

const { Option } = Select;
const { TreeNode } = TreeSelect;
const { Step } = Steps;

const ManageRiskState = ({ actualisation, setActualisation, documentsUploaded, setDocumentsUploaded, stepFromSuivi, idState, allProcessesAndFamilies, allRisksAndFamilies, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, clientUsers }) => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    //LISTENERS CHECK
    // useEffect(() => { if (clientSelected) { console.log('2 clientSelected in AddRiskState : ', clientSelected) } }, [clientSelected])
    // useEffect(() => { if (listFollowed) { console.log('3 listFollowed in AddRiskState : ', listFollowed) } }, [listFollowed])
    // useEffect(() => { if (listByRisks) { console.log('4 listByRisks in AddRiskState : ', listByRisks) } }, [listByRisks])
    // useEffect(() => { if (listByProcesses) { console.log('5 listByProcesses in AddRiskState : ', listByProcesses) } }, [listByProcesses])
    // useEffect(() => { if (stateSelected) { console.log('6 stateSelected in AddRiskState : ', stateSelected) } }, [stateSelected])
    // useEffect(() => { if (clientUsers) { console.log('7 clientUsers in AddRiskState : ', clientUsers) } }, [clientUsers])
    // useEffect(() => { if (allProcessesAndFamilies) { console.log('8 allProcessesAndFamilies in AddRiskState : ', allProcessesAndFamilies) } }, [allProcessesAndFamilies])
    // useEffect(() => { if (allRisksAndFamilies) { console.log('9 allRisksAndFamilies in AddRiskState : ', allRisksAndFamilies) } }, [allRisksAndFamilies])

    //states' states
    const [step, setStep] = useState(1)
    const [current, setCurrent] = useState({})
    const [saving, setSaving] = useState(false)

    const [calculator, setCalculator] = useState(false)
    const [calcul, setCalcul] = useState({})
    const [info, setInfo] = useState(false)
    const [typeInfo, setTypeInfo] = useState('impact')

    useEffect(() => { if (calcul) { console.log('Calcul : ', calcul) } }, [calcul])


    //state to show or not front items
    const [front, setFront] = useState(false)

    //state to actualise and dispatch new listfollowed and stateSelected
    const [saved, setSaved] = useState()
    const [finished, setFinished] = useState(false)

    // useEffect(() => { if (listFollowed) { console.log('listFollowed in ManageRiskState : ', listFollowed) } }, [listFollowed])
    // useEffect(() => { if (saved) { console.log('saved in ManageRiskState : ', saved) } }, [saved])
    // useEffect(() => { if (current) { console.log('current in ManageRiskState : ', current) } }, [current])
    // useEffect(() => { if (stateSelected) { console.log('stateSelected in ManageRiskState : ', stateSelected) } }, [stateSelected])

    //panel item
    const [panelOpen, setPanelOpen] = useState(false)
    const [itemType, setItemType] = useState('process')

    const togglePanel = (item) => {
        if (!panelOpen) {
            setPanelOpen(!panelOpen)
            setItemType(item)
        } else {
            setItemType(item)
        }
    }

    const success = () => {
        message.success('Risque sauvegardé avec succès 👍 ');
    };

    const bug = (err) => {
        message.error('Risque non sauvegardé : ', err);
    };

    //useeffect step if params from suivi
    useEffect(() => {
        if (stepFromSuivi) { setStep(Number(stepFromSuivi)) }
    }, [])


    //handle fields
    useEffect(() => { if (!stateSelected) { setCurrent({ ...current, supports_suivi: documentsUploaded }) } }, [documentsUploaded])
    useEffect(() => {
        if (current.risk) {
            const riskLibelleCopy = allRisksAndFamilies.map(e => e.risks).flat().find(e => e._id === current.risk).libelle
            setCurrent({ ...current, intitule: riskLibelleCopy })
        }
    }, [current.risk])
    useEffect(() => { if (user && !stateSelected) { setCurrent({ ...current, creator: user._id }) } }, [user])
    function handleChangeSelectRisk(value) { setCurrent({ ...current, risk: value }) }
    function handleChangeSelectProcess(value) { setCurrent({ ...current, process: value }) }
    function handleChangeIntitule(e) { setCurrent({ ...current, intitule: e.target.value }) }
    function handleChangeCauses(e) { setCurrent({ ...current, causes: e.target.value }) }
    function handleChangeConsequences(e) { setCurrent({ ...current, consequences: e.target.value }) }
    function handleChangeEvent(e) { setCurrent({ ...current, event: e.target.value }) }
    function onChangeProprietaire(value) { setCurrent({ ...current, proprietaire: value }) }
    function onChangeInterval(value) { setCurrent({ ...current, interval: value }) }

    const handleChangeObjectif = (value) => { setCurrent({ ...current, objectif: value }) }
    const handleChangeMode = (value) => { setCurrent({ ...current, modes_intervention: value }) }
    const handleChangeDomaine = (value) => { setCurrent({ ...current, domaines_competence: value }) }
    const handleChangeIndicateurs = (e) => { setCurrent({ ...current, indicateurs: e.target.value }) }

    const toggleConfiance = (lvl) => { calcul.confiance === lvl ? setCalcul({ ...calcul, confiance: null }) : setCalcul({ ...calcul, confiance: lvl }) }
    const toggleInfo = (type) => { setInfo(!info); setTypeInfo(type) }

    function handleChangeDescMaitrise(e) { setCurrent({ ...current, descr_maitrise: e.target.value }) }
    function handleChangeDescProba(e) { setCurrent({ ...current, arg_proba: e.target.value }) }
    function handleChangeDescImpact(e) { setCurrent({ ...current, arg_impact: e.target.value }) }
    const toggleScoreMaitrise = (value) => { setCurrent({ ...current, lvl_maitrise: value + 1 }) }
    const toggleScoreImpact = (value) => { setCurrent({ ...current, lvl_impact: { brut: value + 1 } }) }
    const toggleScoreProba = (value) => { setCurrent({ ...current, lvl_proba: { brut: value + 1 } }) }

    const handleChangeNatures = (value) => { setCurrent({ ...current, nature: value }) }
    const handleChangeServices = (value) => { setCurrent({ ...current, services_associes: value }) }

    const toggleTargetIntern = (value) => { setCurrent({ ...current, lvl_target_intern: value }) }
    const toggleTargetExtern = (value) => { setCurrent({ ...current, lvl_target_extern: value }) }

    const togglePriority = () => { current.priority != null ? setCurrent({ ...current, priority: !current.priority }) : stateSelected?.priority ? setCurrent({ ...current, priority: !stateSelected.priority }) : setCurrent({ ...current, priority: true }) }

    function handleChangeDescrAttentesIntern(e) { setCurrent({ ...current, descr_attentes_intern: e.target.value }) }
    //function handleChangeDescrAttentesExtern(e) { setCurrent({ ...current, descr_attentes_extern: e.target.value }) }



    //useEffect to actualise listFollowed and stateSelected and dispatch

    useEffect(async () => {

        ///***///***///***///***///
        //PROPALE GPT A INCREMENTER AVEC SURTOUT L'ORDRE DES THEN ET LE RETURN FINDFOLLOWED
        // useEffect(() => {
        //     const fetchStates = (obj) => {
        //       console.log('*** FETCH STATES MANAGERISKSTATE ***');

        //       findClient(obj)
        //         .then((client) => {
        //           if (client) {
        //             console.log('client trouvé, on passe au followed');
        //             dispatch({ type: 'selectClient', client });
        //           }

        //           return findFollowed(obj);
        //         })
        //         .then((followedData) => {
        //           if (followedData) {
        //             console.log('/// json.listFollowed NEW FOUND /// : ', followedData.listFollowedWithIndicateurs.length, followedData.listFollowedWithIndicateurs);
        //             dispatch({ type: 'listFollowed', listFollowed: followedData.listFollowedWithIndicateurs });
        //             dispatch({ type: 'listByProcesses', listByProcesses: followedData.listByProcesses });
        //             dispatch({ type: 'listByRisks', listByRisks: followedData.listByRisks });

        //             if (saved) {
        //               console.log('-----------------------', { saved });
        //               console.log({ listFollowedNew: followedData.listFollowedWithIndicateurs });
        //               console.log(
        //                 followedData.listFollowedWithIndicateurs[followedData.listFollowedWithIndicateurs.length - 1]._id,
        //                 saved,
        //                 typeof followedData.listFollowedWithIndicateurs[0]._id,
        //                 typeof saved
        //               );

        //               let stateSaved = followedData.listFollowedWithIndicateurs.find((e) => e._id === saved);
        //               console.log({ stateSaved });

        //               dispatch({ type: 'selectstate', state: stateSaved });
        //               setSaved();
        //             }
        //           }

        //           console.log('*** FIN FETCH STATES MANAGERISKSTATE ***');
        //         })
        //         .catch((error) => {
        //           console.error('Error in fetchStates:', error);
        //         });
        //     };

        //     if (clientSelected) {
        //       fetchStates(clientSelected);
        //     }
        //   }, [saved]);


        ///***///***///***///***///


        //    console.log('Array in fetchRisks +++++++++++++++++++++ ', obj.followed_risks)
        // console.log('*** FETCH STATES MANAGERISKSTATE ***')

        ///////////////////////////////////

        let followed = []

        const findClient = async (obj) => {
            //get the clientSelected actualised + ATTENTION CONFLIT AVEC ACTUALISATION DANS CONNEXION : VOIR POUR REPORTER MEME MECANISME DANS CONNEXION ?
            // console.log(obj._id)
            const clientData = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-client/${obj._id}`)

            const client = await clientData.json()
            // console.log({client})

            if (client.result) {
                // console.log(client.result, client?.clientFound[0])
                followed = client?.clientFound[0]?.followed_risks
                // console.log('followed in fetch : ', followed, { ...client.clientFound[0], type_hab: obj.type_hab })
                dispatch({ type: 'selectClient', client: { ...client.clientFound[0], type_hab: obj.type_hab } })
            }
        }


        const findFollowed = async (obj) => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-list`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ followed, type_criticity: obj.type_criticity })
            })

            const json = await data.json()

            if (json.result) {
                // console.log( '/// json.listFollowed NEW FOUND /// : ', json.listFollowedWithIndicateurs.length, json.listFollowedWithIndicateurs );
                //        console.log( 'json.listByProcesses : ', json.listByProcesses);
                //        console.log( 'json.listByRisks : ', json.listByRisks);
                dispatch({ type: 'listFollowed', listFollowed: json.listFollowedWithIndicateurs });
                dispatch({ type: 'listByProcesses', listByProcesses: json.listByProcesses });
                dispatch({ type: 'listByRisks', listByRisks: json.listByRisks });

                if (saved) {
                    console.log('-----------------------', {saved})
                    // console.log({ listFollowedNew: json.listFollowedWithIndicateurs })
                    // console.log(json.listFollowedWithIndicateurs[json.listFollowedWithIndicateurs.length-1]._id, saved, typeof(json.listFollowedWithIndicateurs[0]._id), typeof(saved))

                    let stateSaved = json.listFollowedWithIndicateurs.find(e => e._id === saved)
                    // console.log({stateSaved})

                    dispatch({ type: 'selectstate', state: stateSaved })
                    setSaved()
                    setFinished(true)
                }

            }
        }

        //////////////////////////////////////

        const fetchStates = async (obj) => {

            // const all = await Promise.all([findClient(), findFollowed()])
            findClient(obj)
                .then(() => {
                    return findFollowed(obj)
                })

        }

        // console.log('*** FIN FETCH STATES MANAGERISKSTATE ***')

        if (clientSelected) { fetchStates(clientSelected) }

    }, [saved, stateSelected]) //stateSelected, current deleted

    // useEffect(()=>{
    // if(listFollowed && saved) { 

    //     console.log({ listFollowed })
    //     let stateSaved = listFollowed.find(e=>e._id === saved)
    //     console.log({stateSaved})
    //     dispatch({ type: 'selectstate', state: stateSaved })
    // }
    // setSaved()
    // },[listFollowed])


    //useEffect(()=>{console.log('result : ', compareObjects(stateSelected, current))}, [current])

    //function save
    const handleSave = async () => {
        setSaving(true)

        // if (stateSelected && current) {
        //  console.log('comparaison current/state : ', compareObjects(stateSelected, current))
        //  console.log('comparaison state/current : ', compareObjects(current, stateSelected))
        //  console.log('comparaison compared/state : ', compareObjects(compareObjects(stateSelected, current), stateSelected))
        //  console.log('comparaison state/comparedInv : ', compareObjects(compareObjects(current, stateSelected), stateSelected))
        //  console.log('comparaison current/current : ', compareObjects(current, current))
        //  console.log('comparaison state/state : ', compareObjects(stateSelected, stateSelected))
        // }

        let stateToSave = {}
        let isNew = false
        let degree = ''

        //check the degree of change (step 1, 2 or 3) to send the mail to the good one

        if (current.hasOwnProperty('lvl_target_extern') || current.hasOwnProperty('lvl_target_intern') || current.hasOwnProperty('priority')) {
            degree = 'decision'
        } else if (current.hasOwnProperty('lvl_impact') || current.hasOwnProperty('lvl_proba') || current.hasOwnProperty('lvl_maitrise')) {
            degree = 'exposition'
        } else if (current.hasOwnProperty('proprietaire')) {
            degree = 'creation'
        } else {
            degree = 'default'
        }

        // console.log({degree})
        //console.log(stateSelected)

        //compare if existing
        if (stateSelected) {

            if (compareObjects(stateSelected, current).length < 1 || !current) {
                // console.log('------- AUCUN CHANGEMENT --------', current)
                setSaving(false)
                return
            } else if (Object.keys(calcul).length > 0 || current.hasOwnProperty('lvl_impact') || current.hasOwnProperty('lvl_proba') || current.hasOwnProperty('lvl_maitrise') || current.hasOwnProperty('lvl_target_extern') || current.hasOwnProperty('lvl_target_intern') || current.hasOwnProperty('priority')) {

                // console.log(Object.keys(calcul).length > 0 || current.hasOwnProperty('lvl_impact') || current.hasOwnProperty('lvl_proba') || current.hasOwnProperty('lvl_maitrise') || current.hasOwnProperty('lvl_target_extern') || current.hasOwnProperty('lvl_target_intern') || current.hasOwnProperty('priority'))
                //copy stateSelected to put in iteration with replaced_by: user._id
                let copyIteration = { ...stateSelected }
                delete copyIteration.iterations
                delete copyIteration._id

                //prepare other copy of stateSelected with inside the current fields modified replaced and the copy replaced_by            
                let fieldsToUpdate = {
                    ...current,
                    replaced_by: user._id,
                    date_iteration: new Date(),
                    iterations: stateSelected.iterations,
                    calcul: Object.keys(calcul).length > 0 ? calcul : null
                }
                fieldsToUpdate.iterations.push(copyIteration)

                //new calculs of the variables built with basics inputs impact proba and maitrise
                if (fieldsToUpdate.lvl_proba?.brut || fieldsToUpdate.lvl_impact?.brut || fieldsToUpdate.lvl_maitrise) {

                    let probBrut = fieldsToUpdate.lvl_proba?.brut ? fieldsToUpdate.lvl_proba.brut : stateSelected.lvl_proba.brut
                    let impBrut = fieldsToUpdate.lvl_impact?.brut ? fieldsToUpdate.lvl_impact.brut : stateSelected.lvl_impact.brut
                    let maitAct = fieldsToUpdate.lvl_maitrise ? fieldsToUpdate.lvl_maitrise : stateSelected.lvl_maitrise
                    let disp = fieldsToUpdate.dispositif_maitrise ? fieldsToUpdate.dispositif_maitrise : stateSelected.dispositif_maitrise ? stateSelected.dispositif_maitrise : null

                    clientSelected.calcul_net_smooth ?
                    fieldsToUpdate.lvl_proba = { brut: probBrut, net: Math.round(calculNetSmooth(probBrut, impBrut, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) }
                    :
                    fieldsToUpdate.lvl_proba = { brut: probBrut, net: disp ? Math.round(calculNet('proba', probBrut, impBrut, maitAct, disp, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) : probBrut }

                    clientSelected.calcul_net_smooth ?
                    fieldsToUpdate.lvl_impact = { brut: impBrut, net: Math.round(calculNetSmooth(probBrut, impBrut, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10)}
                    :
                    fieldsToUpdate.lvl_impact = { brut: impBrut, net: disp ? Math.round(calculNet('impact', probBrut, impBrut, maitAct, disp, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) : impBrut }
                    
                    let probNet = fieldsToUpdate.lvl_proba.net
                    let impNet = fieldsToUpdate.lvl_impact.net

                    let crit = Math.round(criticity(probBrut, impBrut, clientSelected.type_criticity) * 10) / 10
                    let vuln = Math.round(vulnerability(crit, maitAct) * 100) / 100
                    let rapport = Math.round(rapportGC(probNet, impNet, maitAct) * 100) / 100
                    let prediction = Math.round(predictMaitriseCible(probNet, impNet) * 10) / 10

                    fieldsToUpdate = { ...fieldsToUpdate, crit: crit, vuln: vuln, rapport: rapport, prediction: prediction }

                }

                //console.log('fieldsToUpdate : ', fieldsToUpdate, {clientSelected})

                //send to save
                stateToSave = fieldsToUpdate
                //console.log('in modified key : ', {stateToSave})                
            } else {
                //send to save
                stateToSave = current
                // console.log('in basuc modifs : ', {stateToSave})

            }

        } else {
            isNew = true

            let fieldsToSave = { ...current }

            if (current.lvl_proba?.brut || current.lvl_impact?.brut || current.lvl_maitrise) {

                let probBrut = current.lvl_proba.brut
                let impBrut = current.lvl_impact.brut
                let maitAct = current.lvl_maitrise
                let disp = current.dispositif_maitrise ? current.dispositif_maitrise : null

                // console.log('is new : ', { current })

                clientSelected.calcul_net_smooth ?
                fieldsToSave.lvl_proba = { brut: probBrut, net: Math.round(calculNetSmooth(probBrut, impBrut, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) }
                :
                fieldsToSave.lvl_proba = { brut: probBrut, net: disp ? Math.round(calculNet('proba', probBrut, impBrut, maitAct, disp, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) : probBrut }

                clientSelected.calcul_net_smooth ?
                fieldsToSave.lvl_impact = { brut: impBrut, net: Math.round(calculNetSmooth(probBrut, impBrut, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) }
                :
                fieldsToSave.lvl_impact = { brut: impBrut, net: disp ? Math.round(calculNet('impact', probBrut, impBrut, maitAct, disp, clientSelected?.start_amplitude, clientSelected?.end_amplitude) * 10 / 10) : impBrut }

                let probNet = fieldsToSave.lvl_proba.net
                let impNet = fieldsToSave.lvl_impact.net

                let crit = Math.round(criticity(probBrut, impBrut, clientSelected.type_criticity) * 10) / 10
                let vuln = Math.round(vulnerability(crit, maitAct) * 100) / 100
                let rapport = Math.round(rapportGC(probNet, impNet, maitAct) * 100) / 100
                let prediction = Math.round(predictMaitriseCible(probNet, impNet) * 10) / 10

                fieldsToSave = { ...fieldsToSave, crit: crit, vuln: vuln, rapport: rapport, prediction: prediction }

                // console.log('is new fields to save : ', { fieldsToSave })

            }

            stateToSave = fieldsToSave
            //console.log('in new : ', {stateToSave})
        }

        //console.log('out of if : ', { stateToSave } )

        const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/new-state`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ clientId: clientSelected?._id, stateToSave: stateToSave, isNew: isNew, stateId: stateSelected?._id, degree: degree })
        })
        const json = await data.json()

        if (json.result) {
            // console.log({ isNew })
            // console.log('FULL STATE SAVED TO CHECK ID : ', json.stateSaved)
            if (isNew) {
                // listFollowed.push(json.stateSaved)
                // console.log('NEW ARRAY WITH NEW STATE INSIDE : ', listFollowed.push(json.stateSaved), listFollowed)
                // dispatch({type: 'listFollowed', listFollowed: listFollowed})
            }
            setSaved(json.stateSaved._id)
            await success()
            // console.log('state saved: ', json.stateSaved)
            //console.log('SAVED')
        } else {
            bug(json.error)
        }
        setActualisation(!actualisation)
        setCurrent({})
        setSaving(false)
    }


    // const listRisks = allRisksAndFamilies && allRisksAndFamilies.length > 0 &&
    //                                             allRisksAndFamilies.sort(function (a, b) { return a.code - b.code }).map((e, i) => {
    //                                                 let listRisks = e.risks.sort(function (a, b) { return a.code - b.code }).map((f, j) => {
    //                                                     return <TreeNode key={j} value={f._id} title={`${f.code} · ${f.libelle.length > 50 ? f.libelle.slice(0,50)+'...' : f.libelle}`} />
    //                                                 })
    //                                                 return (
    //                                                     <TreeNode key={i} disabled value={e._id} title={`${e.code} · ${e.libelle.length > 50 ? e.libelle.slice(0,50)+'...' : e.libelle}`}>
    //                                                         {listRisks}
    //                                                     </TreeNode>
    //                                                 )

    //                                             })

    // console.log({listRisks})

    return (

        <>
            <div className='containersuivi'>

                <AddItem actualisation={actualisation} setActualisation={setActualisation} clientSelected={clientSelected} item={itemType} setItemType={setItemType} setPanelOpen={setPanelOpen} panelOpen={panelOpen} />

                <Steps progressDot current={step - 1}>
                    <Step title="" description="Définition" />
                    <Step title="" description="Exposition" />
                    <Step title="" description="Décision" />
                    {/*<Step title="" description="Action" />*/}
                </Steps>
                <span style={{ width: '100%', borderBottom: '1px solid #EDB30D', margin: step === 2 && stateSelected ? '30px 0px 10px 0px' : step === 3 && stateSelected ? '30px 0px 5px 0px' : '30px 0px 20px 0px' }}></span>

                {/* //////////////////////////////////////////STEP 1 INFORMATIONS */}
                {step === 1 &&
                    <>
                        {!stateSelected &&
                            <>
                                {/* BANDEAU 1 DU HAUT */}
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginBottom: 5 }}>
                                    {/* Famille et processus métier */}

                                    <Form.Item
                                        style={{ width: '48%' }}
                                        tooltip="Sélectionner un processus métier"
                                        name="Processus métier"
                                        label="Processus métier"
                                        hasFeedback
                                        validateStatus={current.process ? "success" : ''}
                                        help=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Processus métier',
                                                whitespace: true
                                            },
                                        ]}
                                    >
                                        <TreeSelect
                                            disabled={clientSelected && clientSelected?.type_hab !== 'Risk management' ? true : false}
                                            style={{ width: '100%' }}
                                            value={current.process}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="Sélectionner un processus métier"
                                            allowClear
                                            treeDefaultExpandAll
                                            onChange={handleChangeSelectProcess}
                                        >
                                            {allProcessesAndFamilies && allProcessesAndFamilies.length > 0 &&
                                                allProcessesAndFamilies.map((e, j) => {
                                                    let listProcesses = e.processes.sort(function (a, b) { return a.code - b.code }).map((f, i) => {
                                                        return <TreeNode key={f._id} value={f._id} title={`${f.code} · ${f.libelle}`} />
                                                    })
                                                    return (
                                                        <TreeNode key={e.family} selectable={false} disabled value={e.family} title={e.family}>
                                                            {listProcesses}
                                                        </TreeNode>
                                                    )
                                                })
                                            }
                                        </TreeSelect>
                                    </Form.Item>
                                    <PlusCircleOutlined style={{ paddingBottom: 24, margin: '0px 5px', fontSize: 16, cursor: 'pointer', color: '#49BC17' }} onClick={() => { togglePanel('process') }} />

                                    <Form.Item
                                        style={{ width: '45%', maxWidth: '45%', flexWrap: 'nowrap' }}
                                        tooltip="Sélectionner un type de risque"
                                        name="Risque"
                                        label="Risque"
                                        hasFeedback
                                        validateStatus={current.risk ? "success" : ''}
                                        help=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Risque',
                                                whitespace: true
                                            },
                                        ]}
                                    >
                                        <TreeSelect
                                            disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false}
                                            style={{ width: '100%' }}
                                            value={current.risk}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: '50%', maxWidth: '50%' }}
                                            placeholder="Sélectionner un risque"
                                            allowClear
                                            treeDefaultExpandAll={false}
                                            onChange={handleChangeSelectRisk}
                                        // treeData={[
                                        //     allRisksAndFamilies && allRisksAndFamilies.length > 0 &&
                                        //     allRisksAndFamilies.sort(function (a, b) { return a.code - b.code }).map((e, i) => {
                                        //         let listRisks = e.risks.sort(function (a, b) { return a.code - b.code }).map((f, j) => {
                                        //             return {key:{j}, value:f._id, title:`${f.code} · ${f.libelle.length > 50 ? f.libelle.slice(0,50)+'...' : f.libelle}`}
                                        //         })
                                        //         return {key:{i}, selectable:false, value:e._id, title:`${e.code} · ${e.libelle.length > 50 ? e.libelle.slice(0,50)+'...' : e.libelle}`, children:listRisks}                                                                                                
                                        //     })
                                        // ]}
                                        >
                                            {allRisksAndFamilies && allRisksAndFamilies.length > 0 &&
                                                allRisksAndFamilies.sort(function (a, b) { return a.code - b.code }).map((e, i) => {
                                                    let listRisks = e.risks.sort(function (a, b) { return a.code - b.code }).map((f, j) => {
                                                        return <TreeNode key={f._id} value={f._id} title={`${f.code} · ${f.libelle.length > 50 ? f.libelle.slice(0, 50) + '...' : f.libelle}`} />
                                                    })
                                                    return (
                                                        <TreeNode key={e._id} selectable={false} value={e._id} title={`${e.code} · ${e.libelle.length > 50 ? e.libelle.slice(0, 50) + '...' : e.libelle}`}>
                                                            {listRisks}
                                                        </TreeNode>
                                                    )

                                                })
                                            }
                                        </TreeSelect>
                                    </Form.Item>
                                    <PlusCircleOutlined style={{ paddingBottom: 24, marginLeft: 5, fontSize: 16, cursor: 'pointer', color: '#49BC17' }} onClick={() => { togglePanel('risk') }} />

                                </div>
                                {/* BANDEAU 2 DU HAUT */}
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%' }}>

                                    {/* Intitule du risque */}
                                    <Form.Item
                                        style={{ width: '48%' }}
                                        tooltip="Identique au type de risque sauf si finesse supplémentaire souhaitée"
                                        name="Intitulé du risque"
                                        label="Intitulé du risque"
                                        hasFeedback
                                        validateStatus={current.intitule ? "success" : ''}
                                        help=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Désolé, obligatoire 😁',
                                                whitespace: true
                                            },
                                        ]}
                                    >
                                        <Input disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} placeholder={current.intitule} value={current.intitule} onChange={handleChangeIntitule} />
                                    </Form.Item>

                                    {/* Créateur : user */}

                                    {/* Proprietaire */}
                                    {clientUsers &&
                                        <Form.Item
                                            style={{ width: '48%', maxWidth: '48%', flexWrap: 'nowrap' }}
                                            tooltip="Sélectionner un propriétaire à qui affecter ce risque"
                                            name="Propriétaire"
                                            label="Propriétaire"
                                            hasFeedback
                                            validateStatus={current.proprietaire ? "success" : ''}
                                            help=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Désolé, obligatoire 😁',
                                                },
                                            ]}
                                        >
                                            <Select
                                                disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false}
                                                showSearch
                                                placeholder="Sélectionner ou rechercher..."
                                                optionFilterProp="children"
                                                onChange={onChangeProprietaire}
                                            >
                                                {clientUsers.length > 0 &&
                                                    clientUsers.filter((e) => e._id != "62699350bf7c7ded37e4dfdb").map((e, i) => {
                                                        //console.log({clientUsers})
                                                        return <Option key={i} value={e._id}><img src={e.avatar} style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }} />{e.firstname} {e.lastname} - {e.role?.length > 28 ? e.role.slice(0, 28) + '...' : e.role}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    }
                                </div>
                            </>
                        }
                        {/* BANDEAU 1 DU HAUT */}
                        {stateSelected &&
                            <>
                                {/* BANDEAU 1 DU HAUT */}
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginBottom: 5 }}>
                                    {/* Famille et processus métier */}

                                    <Form.Item
                                        style={{ width: '48%' }}
                                        tooltip="Sélectionner un processus métier"
                                        name="Processus métier"
                                        label="Processus métier"
                                        hasFeedback
                                        validateStatus={current.process ? "success" : ''}
                                        help=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Processus métier',
                                                whitespace: true
                                            },
                                        ]}
                                    >
                                        <TreeSelect
                                            disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false}
                                            style={{ width: '100%' }}
                                            value={current.process}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder='Sélectionnez un processus métier...'
                                            defaultValue={`${stateSelected?.process.libelle}`}
                                            allowClear
                                            treeDefaultExpandAll
                                            onChange={handleChangeSelectProcess}
                                        >
                                            {allProcessesAndFamilies && allProcessesAndFamilies.length > 0 &&
                                                allProcessesAndFamilies.map((e, i) => {
                                                    let listProcesses = e.processes.sort(function (a, b) { return a.code - b.code }).map((f, j) => {
                                                        return <TreeNode key={f._id} value={f._id} title={`${f.code} · ${f.libelle}`} />
                                                    })
                                                    return (
                                                        <TreeNode key={e.family} selectable={false} disabled value={e.family} title={e.family}>
                                                            {listProcesses}
                                                        </TreeNode>
                                                    )
                                                })
                                            }
                                        </TreeSelect>
                                    </Form.Item>
                                    <PlusCircleOutlined style={{ paddingBottom: 24, margin: '0px 5px', fontSize: 16, cursor: 'pointer', color: '#49BC17' }} onClick={() => { togglePanel('process') }} />

                                    <Form.Item
                                        style={{ width: '48%', maxWidth: '48%', flexWrap: 'nowrap' }}
                                        tooltip="Sélectionner un type de risque"
                                        name="Risque"
                                        label="Risque"
                                        hasFeedback
                                        validateStatus={current.risk ? "success" : ''}
                                        help=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Risque',
                                                whitespace: true
                                            },
                                        ]}
                                    >
                                        <TreeSelect
                                            disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false}
                                            style={{ width: '100%' }}
                                            value={current.risk}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder='Sélectionnez un risque...'
                                            defaultValue={`${stateSelected?.risk.libelle.length > 50 ? stateSelected?.risk.libelle.slice(0, 50) + '...' : stateSelected?.risk.libelle}`}
                                            allowClear
                                            treeDefaultExpandAll={false}
                                            onChange={handleChangeSelectRisk}
                                        >
                                            {allRisksAndFamilies && allRisksAndFamilies.length > 0 &&
                                                allRisksAndFamilies.sort(function (a, b) { return a.code - b.code }).map((e, i) => {
                                                    let listRisks = e.risks.sort(function (a, b) { return a.code - b.code }).map((f, j) => {
                                                        return <TreeNode key={f._id} value={f._id} title={`${f.code} · ${f.libelle.length > 50 ? f.libelle.slice(0, 50) + '...' : f.libelle}`} />
                                                    })
                                                    return (
                                                        <TreeNode key={e._id} selectable={false} value={e._id} title={`${e.code} · ${e.libelle.length > 50 ? e.libelle.slice(0, 50) + '...' : e.libelle}`}>
                                                            {listRisks}
                                                        </TreeNode>
                                                    )

                                                })
                                            }
                                        </TreeSelect>
                                    </Form.Item>
                                    <PlusCircleOutlined style={{ paddingBottom: 24, margin: '0px 5px', fontSize: 16, cursor: 'pointer', color: '#49BC17' }} onClick={() => { togglePanel('risk') }} />

                                </div>
                                {/* BANDEAU 2 DU HAUT */}
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%' }}>

                                    {/* Intitule du risque */}
                                    <Form.Item
                                        style={{ width: '48%' }}
                                        tooltip="Identique au type de risque sauf si finesse supplémentaire souhaitée"
                                        name="Intitulé du risque"
                                        label="Intitulé du risque"
                                        hasFeedback
                                        validateStatus={current.intitule ? "success" : ''}
                                        help=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Désolé, obligatoire 😁',
                                                whitespace: true
                                            },
                                        ]}
                                    >
                                        <Input disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} placeholder={current.intitule} defaultValue={`${stateSelected.intitule}`} value={current.intitule} onChange={handleChangeIntitule} />
                                    </Form.Item>

                                    {/* Créateur : user */}

                                    {/* Proprietaire */}
                                    {clientUsers &&
                                        <Form.Item
                                            style={{ width: '48%', maxWidth: '48%', flexWrap: 'nowrap' }}
                                            tooltip="Sélectionner un propriétaire à qui affecter ce risque"
                                            name="Propriétaire"
                                            label="Propriétaire"
                                            hasFeedback
                                            validateStatus={current.proprietaire ? "success" : ''}
                                            help=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Désolé, obligatoire 😁',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                defaultValue={`${stateSelected.proprietaire.firstname} ${stateSelected.proprietaire.lastname} - ${stateSelected.proprietaire.role}`}
                                                optionFilterProp="children"
                                                onChange={onChangeProprietaire}
                                            >
                                                {clientUsers.length > 0 &&
                                                    clientUsers.filter((e) => e._id != "62699350bf7c7ded37e4dfdb").map((e, j) => {
                                                        return <Option key={j} value={e._id}><img src={e.avatar} style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }} />{e.firstname} {e.lastname} - {e.role.length > 28 ? e.role.slice(0, 28) + '...' : e.role}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    }
                                </div>
                            </>
                        }

                        {/* DEUX COLONNES EN DESSOUS */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%', width: '95%', marginBottom: 20 }}>

                            {/* COLONNE DE GAUCHE */}
                            <div className='subcontainercreate'>

                                {/* Causes et conséquences */}
                                <Form.Item
                                    tooltip='Quel évènement est associé au risque considéré ?'
                                    name="Evènement associé"
                                    label="Evènement associé au risque considéré"
                                    rules={[{ required: true, message: 'Quel évènement est associé au risque considéré ?' }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} defaultValue={stateSelected?.event} onChange={handleChangeEvent} showCount autoSize={{ minRows: 2, maxRows: 2 }} maxLength={500} />
                                </Form.Item>

                                <Form.Item
                                    tooltip='Quelles sont les causes avérées ou supposées du risque observé ?'
                                    name="Causes potentielles ou identifiées du risque"
                                    label="Causes potentielles ou identifiées du risque"
                                    rules={[{ required: true, message: 'Quelles sont les causes avérées ou supposées du risque observé ?' }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} defaultValue={stateSelected?.causes} onChange={handleChangeCauses} showCount autoSize={{ minRows: 3, maxRows: 3 }} maxLength={1500} />
                                </Form.Item>

                                <Form.Item
                                    vertical
                                    tooltip={`Quelles conséquences en cas d'incident ou de suite d'incidents sur le risque observé ?`}
                                    name="Conséquences potentielles en cas de survenue du risque"
                                    label="Conséquences potentielles en cas de survenue du risque"
                                    rules={[{ required: true, message: `Quelles conséquences en cas d'incident ou de suite d'incidents sur le risque observé ?` }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} defaultValue={stateSelected?.consequences} onChange={handleChangeConsequences} showCount autoSize={{ minRows: 3, maxRows: 3 }} maxLength={1500} />
                                </Form.Item>

                            </div>

                            {/* COLONNE DE DROITE */}
                            <div className='subcontainercreate' style={{ justifyContent: 'flex-start' }}>

                                <span style={{ width: '100%', alignSelf: 'left', marginBottom: 10 }}>Objectif COSO :</span>
                                <Select disabled={(!clientSelected?.objectif_activated) || clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} style={{ width: '100%', marginBottom: 10 }} defaultValue={stateSelected?.objectif} placeholder="Sélectionner un objectif COSO" onChange={handleChangeObjectif}>
                                    {['Performance', 'Conformité', 'Reporting'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                                </Select>

                                {//front &&
                                    <>
                                        <span style={{ width: '100%', alignSelf: 'left', marginBottom: 10 }}>Modes d'intervention :</span>
                                        <Select disabled={(!clientSelected?.modes_activated) || (clientSelected && clientSelected?.type_hab != 'Risk management') ? true : false} mode="tags" style={{ width: '100%', marginBottom: 10 }} maxTagCount={2} defaultValue={stateSelected?.modes_intervention} placeholder="Sélectionner une ou plusieurs mode(s) d'intervention" onChange={handleChangeMode}>
                                            {[
                                                'Octroi de subventions',
                                                'Remboursement de frais',
                                                'Attribution d’aides individuelles',
                                                'Passation de marchés',
                                                'Mise en place de régies',
                                                'Délégation de service public',
                                                'Octroi de garanties, prêts ou cautions',
                                                'Autre',
                                                `Tous modes`
                                            ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                                        </Select>


                                        <span style={{ width: '100%', alignSelf: 'left', marginBottom: 10 }}>Domaines de compétence :</span>
                                        <Select disabled={(!clientSelected?.domaines_activated) || clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} mode="tags" style={{ width: '100%', marginBottom: 10 }} maxTagCount={2} defaultValue={stateSelected?.domaines_competence} placeholder="Sélectionner un ou plusieurs domaine(s) de compétence" onChange={handleChangeDomaine}>
                                            {[
                                                'Éducation et formation',
                                                'Voirie',
                                                'Social',
                                                'Culture',
                                                'Sport',
                                                'Aménagement du territoire',
                                                'Ruralité',
                                                'Environnement et développement durable',
                                                'Patrimoine',
                                                'Économie',
                                                'Transports',
                                                'Eau et assainissement',
                                                'Autre',
                                                'Tous domaines'
                                            ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                                        </Select>
                                    </>
                                }

                                <Form.Item
                                    vertical
                                    tooltip={`Quels sont les indicateurs retenus pour objectiver le risque ?`}
                                    name="Indicateurs retenus d'objectivation du risque"
                                    label="Indicateurs retenus d'objectivation du risque"
                                    rules={[{ required: false, message: `Quels sont les indicateurs retenus pour objectiver le risque ?` }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} defaultValue={stateSelected?.indicateurs} onChange={handleChangeIndicateurs} showCount autoSize={{ minRows: 4, maxRows: 4 }} maxLength={500} />
                                </Form.Item>

                            </div>
                        </div>

                    </>
                }
                {/* //////////////////////////////////////////STEP 2 INCIDENTS */}
                {step === 2 &&
                    <>

                        {/* BANDEAU 1 DU HAUT */}
                        {stateSelected &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginBottom: 5 }}>

                                <h2 title={stateSelected.intitule} style={{ fontSize: 20, margin: 0 }}>{formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>

                                {stateSelected.replaced_by &&
                                    <span>{`Modifié le ${moment(stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by?.firstname} ${stateSelected.replaced_by?.lastname}`}<img className='avatar-right' src={stateSelected.replaced_by?.avatar} /></span>
                                }
                            </div>
                        }
                        {/* BANDEAU 2 DU HAUT */}
                        {stateSelected &&

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%' }}>

                                <div><span>{`Propriétaire : ${stateSelected.proprietaire?.habilitations.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected.proprietaire?.firstname} ${stateSelected.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected.proprietaire?.avatar} /></div>
                                <div><span>{`Risque créé le ${stateSelected.iterations?.length > 0 ? moment(stateSelected.iterations[stateSelected.iterations.length - 1].date_creation).format('LL') : moment(stateSelected.date_creation).format('LL')} par ${stateSelected?.creator?.firstname} ${stateSelected?.creator?.lastname}`}</span><img className='avatar-right' src={user.avatar} /></div>


                            </div>
                        }

                        {/* TROIS COLONNES EN DESSOUS */}
                        <div style={{ paddingTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%', width: '95%', marginBottom: 20 }}>

                            {/* COLONNE DE GAUCHE */}
                            <div className='subcontainercreate' style={{ justifyContent: 'flex-start' }}>

                                {/* Probas */}

                                <Form.Item
                                    tooltip='Quelles sont les probabilités avérées ou supposées du risque observé ?'
                                    name="Indicateurs de probabilité de survenue du risque"
                                    label="Indicateurs de probabilité de survenue du risque"
                                    rules={[{ required: true, message: 'Quelles sont les probabilités avérées ou supposées du risque observé ?' }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab === 'Contribution métier' || 'Risk management' ? false : true} defaultValue={stateSelected?.arg_proba} value={current.arg_proba} onChange={handleChangeDescImpact} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={1000} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginTop: 31, width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}
                                    tooltip="Temporalité pertinente d'observation du risque (en mois)"
                                    name="Temporalité d'observation du risque"
                                    label="Temporalité d'observation"
                                    help=""
                                >
                                    <InputNumber disabled={clientSelected && clientSelected?.type_hab != 'Risk management' ? true : false} defaultValue={stateSelected?.interval} value={current.interval} onChange={onChangeInterval} min={1} max={60} formatter={value => `${value} mois`} parser={value => value.replace(' mois', '')} />
                                </Form.Item>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <span style={{ marginTop: 0, marginBottom: -5 }} > Probabilité : </span>
                                    <ScoreDotsParametrable
                                        clientSelected={clientSelected}
                                        toggleScore={toggleScoreProba}
                                        value={Object.keys(calcul).length > 0 ? calcul.proba : current.lvl_proba?.brut ? current.lvl_proba?.brut : stateSelected?.lvl_proba.brut}
                                        reverse={true}
                                        editMode={clientSelected?.methodo_le_ray ? false : true}
                                    />
                                </div>

                            </div>
                            {clientSelected?.methodo_le_ray &&
                                <>
                                    <div onClick={() => { setCalculator(!calculator) }} title={`Déterminer la probabilité et l'impact`} style={{ cursor: 'pointer', position: 'absolute', bottom: '18%', left: '22%', width: 50, height: 50, borderRadius: '50%', backgroundColor: '#EEE', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                        <SlidersOutlined style={{ fontSize: 30 }} />
                                    </div>
                                    <span onClick={() => { setCalculator(!calculator) }} style={{ cursor: 'pointer', position: 'absolute', bottom: '19.5%', left: '25%', width: 200 }}>Calibrer l'exposition</span>
                                    {/* <span syle={{fontWeight: 'bolder'}}>{clientSelected?.score?.proba[calcul.proba] ? ' : ' + scoreTotal(clientSelected?.score, clientSelected?.calcul ? clientSelected?.calcul : calcul) : ''}</span> */}
                                    {clientSelected?.score && (stateSelected?.calcul || calcul.proba) &&
                                        <>
                                            <div onClick={() => { setCalculator(!calculator) }} title={`Score`} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', top: '17%', left: '19%', width: 50, height: 50, borderRadius: 3, color: scoreTotal(clientSelected?.score, stateSelected?.calcul ? stateSelected?.calcul : calcul) > 10 ? '#FFF' : '#222', backgroundColor: scoreTotal(clientSelected?.score, stateSelected?.calcul ? stateSelected?.calcul : calcul) > 500 ? '#7E005D' : scoreTotal(clientSelected?.score, stateSelected?.calcul ? stateSelected?.calcul : calcul) > 50 ? '#C80094' : scoreTotal(clientSelected?.score, stateSelected?.calcul ? stateSelected?.calcul : calcul) > 10 ? '#FF0DC0' : scoreTotal(clientSelected?.score, stateSelected?.calcul ? stateSelected?.calcul : calcul) > 2 ? '#FF9BE5' : '#FFD9F5', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 'bold' }}>
                                                {(clientSelected?.score?.proba[calcul.proba] || clientSelected?.score?.proba[stateSelected?.calcul.proba]) ? scoreTotal(clientSelected?.score, stateSelected?.calcul ? stateSelected?.calcul : calcul) : ''}
                                            </div>
                                            {(stateSelected?.calcul?.confiance || calcul?.confiance) && <span style={{ zIndex: 1, position: 'absolute', top: '21.75%', left: '19.5%', fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: '#CCC', color: '#222', borderRadius: '50%' }}>{calcul?.confiance ? calcul?.confiance : stateSelected?.calcul?.confiance ? stateSelected?.calcul?.confiance : ''}</span>}
                                        </>
                                    }
                                </>
                            }

                            {/* COLONNE DU MILIEU */}
                            <div className='subcontainercreate' style={{ justifyContent: 'flex-start' }}>

                                <Form.Item
                                    tooltip={`Quels sont les impacts potentiels en cas d'incident ou de suite d'incidents sur le risque observé ?`}
                                    name="Impact potentiel en cas de survenue du risque"
                                    label="Impact potentiel en cas de survenue du risque"
                                    rules={[{ required: true, message: `Quels sont les impacts potentiels en cas d'incident ou de suite d'incidents sur le risque observé ?` }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab === 'Contribution métier' || 'Risk management' ? false : true} defaultValue={stateSelected?.arg_impact} value={current.arg_impact} onChange={handleChangeDescProba} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={1000} />
                                </Form.Item>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>

                                    <span style={{ width: '100%', alignSelf: 'left', marginBottom: 10 }}>Nature(s) d'impact :</span>
                                    <Select disabled={clientSelected && clientSelected?.type_hab === 'Risk management' || 'Contribution métier' ? false : true} maxTagCount={2} defaultValue={stateSelected?.nature} mode="tags" style={{ width: '100%', marginBottom: 10 }} placeholder="Sélectionner une ou plusieurs nature(s)" onChange={handleChangeNatures}>
                                        {["Financier", "Organisationnel", "Réputationnel", "Juridique", "Humain", "Opérationnel", "Stratégique"].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                                    </Select>

                                    <span style={{ marginTop: 10, marginBottom: -5 }} >Impact : </span>
                                    <ScoreDotsParametrable
                                        clientSelected={clientSelected}
                                        toggleScore={toggleScoreImpact}
                                        value={Object.keys(calcul).length > 0 ? Math.max(calcul.juridique ? calcul.juridique : 0, calcul.humain ? calcul.humain : 0, calcul.immateriel ? calcul.immateriel : 0, calcul.operationnel ? calcul.operationnel : 0, calcul.evenement ? calcul.evenement : 0, calcul.financier ? calcul.financier : 0) : current?.lvl_impact?.brut ? current?.lvl_impact?.brut : stateSelected?.lvl_impact?.brut}
                                        reverse={true}
                                        editMode={clientSelected?.methodo_le_ray ? false : true}
                                    />
                                </div>

                            </div>

                            {/* COLONNE DE DROITE */}
                            <div className='subcontainercreate' style={{ justifyContent: 'flex-start' }}>

                                <Form.Item
                                    tooltip={`Quel sont les dispositifs déployés pour maîtriser la survenue et/ou les conséquences du risque ?`}
                                    name="Description du dispositif de maîtrise déployé"
                                    label="Description du dispositif de maîtrise déployé"
                                    rules={[{ required: true, message: `Quel sont les dispositifs déployés pour maîtriser la survenue et/ou les conséquences du risque ?` }]}
                                >
                                    <Input.TextArea disabled={clientSelected && clientSelected?.type_hab === 'Contribution métier' || 'Risk management' ? false : true} defaultValue={stateSelected?.descr_maitrise} value={current.descr_maitrise} onChange={handleChangeDescMaitrise} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={1000} />
                                </Form.Item>



                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>

                                    <span style={{ width: '100%', alignSelf: 'left', marginBottom: 10 }}>Service(s) associé(s) :</span>
                                    <Select disabled={clientSelected && clientSelected?.type_hab === 'Risk management' || 'Contribution métier' ? false : true} maxTagCount={2} mode="tags" defaultValue={stateSelected?.services_associes} style={{ width: '100%', marginBottom: 20 }} placeholder="Entrez un ou plusieurs service(s)" onChange={handleChangeServices} tokenSeparators={[',', ';', ':', '-']}>
                                        {[].map((e, i) => { return <Option key={e}>{e}</Option> })}
                                    </Select>

                                    <span style={{ marginBottom: -5 }} >Maîtrise : </span>                                    
                                    <ScoreDotsParametrable
                                        clientSelected={clientSelected}
                                        toggleScore={toggleScoreMaitrise}
                                        value={current?.lvl_maitrise ? current?.lvl_maitrise : stateSelected?.lvl_maitrise}
                                        reverse={false}
                                        editMode={true}
                                    />
                                </div>

                                <span style={{ marginBottom: 10, marginTop: 20 }} >Sélectionner le type de dispositif : </span>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%' }}>

                                    {clientSelected && clientSelected?.type_hab === 'Risk management' &&
                                        ['Préventif', 'Détectif', 'Curatif', 'Mixte'].map((el, i) => {
                                            return <span key={i} onClick={() => { setCurrent({ ...current, dispositif_maitrise: el }) }} style={{
                                                cursor: 'pointer', lineHeight: '50%', height: '100%', minWidth: '25%', margin: '0px 5px',
                                                padding: current.dispositif_maitrise === el ? '6px 5px' : (!current.dispositif_maitrise && stateSelected?.dispositif_maitrise === el) ? '6px 5px' : '10px 5px',
                                                borderRadius: 10,
                                                border: current.dispositif_maitrise === el ? '2px solid #C36F09' : (!current.dispositif_maitrise && stateSelected?.dispositif_maitrise === el) ? '2px solid #C36F09' : '',
                                                color: current.dispositif_maitrise === el ? '#FFF' : (!current.dispositif_maitrise && stateSelected?.dispositif_maitrise === el) ? '#FFF' : '#333',
                                                backgroundColor: current.dispositif_maitrise === el ? '#EDB30D' : (!current.dispositif_maitrise && stateSelected?.dispositif_maitrise === el) ? '#EDB30D' : '#FEF375',
                                                fontWeight: 'bold', textAlign: 'center'
                                            }}>{el}</span>
                                        })
                                    }
                                    {clientSelected && clientSelected?.type_hab != 'Risk management' &&
                                        ['Préventif', 'Détectif', 'Curatif', 'Mixte'].map((el, i) => {
                                            return <span key={i} style={{
                                                cursor: 'pointer', lineHeight: '50%', height: '100%', minWidth: '25%', margin: '0px 5px',
                                                padding: stateSelected.dispositif_maitrise === el ? '6px 5px' : '10px 5px',
                                                borderRadius: 10,
                                                border: stateSelected.dispositif_maitrise === el ? '2px solid #C36F09' : '',
                                                color: stateSelected.dispositif_maitrise === el ? '#FFF' : '#333',
                                                backgroundColor: stateSelected.dispositif_maitrise === el ? '#EDB30D' : '#FEF375',
                                                fontWeight: 'bold', textAlign: 'center'
                                            }}>{el}</span>
                                        })
                                    }
                                </div>

                            </div>

                        </div>

                        {/* Calculator */}
                        {calculator &&
                            <div style={{ height: '55%', width: '60%', backgroundColor: "#FFF", position: "absolute", top: '31%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'space-between' }}>
                                <h3 style={{ textAlign: 'center', height: 30, width: '100%', backgroundColor: '#EEE' }}>Détermination de la probabilité et de l'impact</h3>
                                <CloseOutlined style={{ position: 'absolute', top: 6, right: 10 }} onClick={() => { setCalculator(!calculator); setCalcul({}) }} />

                                <div style={{ width: '100%', backgroundColor: "#EEE", padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'space-between' }}>
                                    <span style={{ width: '20%', fontWeight: 'bold' }}>Evènement redouté :</span> {stateSelected ? stateSelected?.event : current?.event}
                                </div>

                                <div style={{ width: '100%', minHeight: '80%', maxHeight: '80%', backgroundColor: "#FFF", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'space-between' }}>
                                    <div style={{ height: '100%', minHeight: '80%', maxHeight: '100%', overflowY: 'auto', marginRight: 0, width: '30%', padding: 10, display: 'flex', backgroundColor: '#EEE', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }} >
                                        <span style={{ textAlign: 'justify', fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>Causes :</span> {stateSelected ? stateSelected?.causes : current?.causes}</span>
                                        <span style={{ textAlign: 'justify', fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>Conséquences :</span> {stateSelected ? stateSelected?.consequences : current?.consequences}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Juridique</span>
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.juridique} value={calcul.juridique} onChange={(e) => { setCalcul({ ...calcul, juridique: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#EEE', borderRadius: '50%' }}>{clientSelected?.score?.impact[calcul.juridique]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Financier</span>
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.financier} value={calcul.financier} onChange={(e) => { setCalcul({ ...calcul, financier: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#EEE', borderRadius: '50%' }}>{clientSelected?.score?.impact[calcul.financier]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Humain</span>
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.humain} value={calcul.humain} onChange={(e) => { setCalcul({ ...calcul, humain: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#EEE', borderRadius: '50%' }}>{clientSelected?.score?.impact[calcul.humain]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Opérationnel</span>
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.operationnel} value={calcul.operationnel} onChange={(e) => { setCalcul({ ...calcul, operationnel: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#EEE', borderRadius: '50%' }}>{clientSelected?.score?.impact[calcul.operationnel]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Immatériel</span>
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.immateriel} value={calcul.immateriel} onChange={(e) => { setCalcul({ ...calcul, immateriel: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#EEE', borderRadius: '50%' }}>{clientSelected?.score?.impact[calcul.immateriel]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Evènement</span>
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.evenement} value={calcul.evenement} onChange={(e) => { setCalcul({ ...calcul, evenement: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#EEE', borderRadius: '50%' }}>{clientSelected?.score?.impact[calcul.evenement]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Impact</span>
                                        <InfoCircleOutlined style={{ cursor: 'pointer', fontSize: 20, color: 'lightblue', position: 'absolute', top: '25%' }} onClick={() => { toggleInfo('impact') }} />
                                        <span style={{ marginTop: 0, fontWeight: 'bold', height: '12%' }}></span>
                                        <span style={{ marginTop: 0, fontWeight: 'bold', height: '10%' }}></span>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#AAA', color: '#FFF', borderRadius: '50%' }}>{Object.keys(calcul).length > 0 ? Math.max(clientSelected?.score?.impact[calcul.juridique] ? clientSelected?.score?.impact[calcul.juridique] : 0, clientSelected?.score?.impact[calcul.humain] ? clientSelected?.score?.impact[calcul.humain] : 0, clientSelected?.score?.impact[calcul.immateriel] ? clientSelected?.score?.impact[calcul.immateriel] : 0, clientSelected?.score?.impact[calcul.operationnel] ? clientSelected?.score?.impact[calcul.operationnel] : 0, clientSelected?.score?.impact[calcul.evenement] ? clientSelected?.score?.impact[calcul.evenement] : 0, clientSelected?.score?.impact[calcul.financier] ? clientSelected?.score?.impact[calcul.financier] : 0) : ''}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Probabilité</span>
                                        <InfoCircleOutlined style={{ cursor: 'pointer', fontSize: 20, color: 'lightblue', position: 'absolute', top: '25%' }} onClick={() => { toggleInfo('proba') }} />
                                        <InputNumber style={{ height: '12%', width: '60%' }} defaultValue={stateSelected?.calcul?.proba} value={calcul.proba} onChange={(e) => { setCalcul({ ...calcul, proba: e }) }} min={1} max={4}></InputNumber>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', height: '10%' }}>Score</span>
                                        <span style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 18, height: 40, width: 40, backgroundColor: '#AAA', color: '#FFF', borderRadius: '50%' }}>{clientSelected?.score?.proba[calcul.proba]}</span>
                                    </div>

                                    <div style={{ margin: 5, width: '12%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '8%' }} >
                                        <span style={{ marginBottom: 20, fontWeight: 'bold' }}>Score final</span>
                                        <span style={{ marginTop: 0, fontWeight: 'bold', height: '12%' }}></span>
                                        <span style={{ marginTop: 0, fontWeight: 'bold', height: '10%' }}></span>
                                        <span style={{ marginTop: 20, fontWeight: 'bold', textAlign: 'center', lineHeight: '40px', fontSize: 20, height: 40, width: 40, backgroundColor: '#555', color: '#FFF', borderRadius: '50%' }}>{clientSelected?.score?.proba[calcul.proba] ? clientSelected?.score?.proba[calcul.proba] * Math.max(clientSelected?.score?.impact[calcul.juridique] ? clientSelected?.score?.impact[calcul.juridique] : 0, clientSelected?.score?.impact[calcul.humain] ? clientSelected?.score?.impact[calcul.humain] : 0, clientSelected?.score?.impact[calcul.immateriel] ? clientSelected?.score?.impact[calcul.immateriel] : 0, clientSelected?.score?.impact[calcul.operationnel] ? clientSelected?.score?.impact[calcul.operationnel] : 0, clientSelected?.score?.impact[calcul.evenement] ? clientSelected?.score?.impact[calcul.evenement] : 0, clientSelected?.score?.impact[calcul.financier] ? clientSelected?.score?.impact[calcul.financier] : 0) : ''}</span>
                                    </div>

                                    {info &&
                                        <div style={{ zIndex: 80, position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                            <CloseOutlined style={{ color: '#FFFFFF', position: 'absolute', top: 6, right: 10 }} onClick={() => { toggleInfo(typeInfo) }} />
                                            <img onClick={() => { toggleInfo(typeInfo) }} src={typeInfo === 'impact' ? "https://res.cloudinary.com/asterion/image/upload/v1683214728/echelle_impact_rjaaas.png" : "https://res.cloudinary.com/asterion/image/upload/v1683214728/echelle_proba_nmgock.png"} alt='Matrice de criticité' />
                                        </div>
                                    }

                                    {clientSelected?.type_hab === 'Risk management' &&
                                        <div style={{ position: 'absolute', bottom: '15%', right: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
                                            <span style={{ fontWeight: 'bold' }}>Indice de confiance</span>
                                            <span id='A' onClick={(e) => { toggleConfiance(e.target.id) }} style={{ cursor: 'pointer', marginLeft: 10, fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: calcul?.confiance ? calcul?.confiance === 'A' ? '#AAA' : !calcul?.confiance && stateSelected?.calcul?.confiance === 'A' ? '#AAA' : '#EEEEEE' : '#EEEEEE', color: '#222', borderRadius: '50%' }}>A</span>
                                            <span id='B' onClick={(e) => { toggleConfiance(e.target.id) }} style={{ cursor: 'pointer', marginLeft: 10, fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: calcul?.confiance ? calcul?.confiance === 'B' ? '#AAA' : !calcul?.confiance && stateSelected?.calcul?.confiance === 'B' ? '#AAA' : '#EEEEEE' : '#EEEEEE', color: '#222', borderRadius: '50%' }}>B</span>
                                            <span id='C' onClick={(e) => { toggleConfiance(e.target.id) }} style={{ cursor: 'pointer', marginLeft: 10, fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: calcul?.confiance ? calcul?.confiance === 'C' ? '#AAA' : !calcul?.confiance && stateSelected?.calcul?.confiance === 'C' ? '#AAA' : '#EEEEEE' : '#EEEEEE', color: '#222', borderRadius: '50%' }}>C</span>
                                        </div>
                                    }

                                    {Object.keys(calcul).length > 1 && calcul.proba &&
                                        <Button texte='Valider' styleAdded={{ position: 'absolute', bottom: '15%', right: '35%', width: '10%' }}
                                            onClick={() => {
                                                setCalculator(!calculator);
                                                setCurrent({ ...current, lvl_proba: { brut: calcul.proba }, lvl_impact: { brut: Math.max(calcul.juridique ? calcul.juridique : 0, calcul.humain ? calcul.humain : 0, calcul.immateriel ? calcul.immateriel : 0, calcul.operationnel ? calcul.operationnel : 0, calcul.evenement ? calcul.evenement : 0, calcul.financier ? calcul.financier : 0) } })
                                            }} />
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
                {/* //////////////////////////////////////////STEP 3 DECISION */}
                {step === 3 &&
                    <>
                        {/* BANDEAU 1 DU HAUT */}
                        {stateSelected &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginBottom: 5 }}>

                                <h2 title={stateSelected.intitule} style={{ margin: 0 }}>{formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>

                                {stateSelected.replaced_by &&
                                    <span>{`Modifié le ${moment(stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by?.firstname} ${stateSelected.replaced_by?.lastname}`}<img className='avatar-right' src={stateSelected.replaced_by?.avatar} /></span>
                                }
                            </div>
                        }
                        {/* BANDEAU 2 DU HAUT */}
                        {stateSelected &&

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%' }}>

                                <div><span>{`Propriétaire : ${stateSelected.proprietaire?.habilitations.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected.proprietaire?.firstname} ${stateSelected.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected.proprietaire?.avatar} /></div>
                                <div><span>{`Risque créé le ${stateSelected.iterations?.length > 0 ? moment(stateSelected.iterations[stateSelected.iterations.length - 1].date_creation).format('LL') : moment(stateSelected.date_creation).format('LL')} par ${stateSelected?.creator?.firstname} ${stateSelected?.creator?.lastname}`}</span><img className='avatar-right' src={user.avatar} /></div>


                            </div>
                        }
                        {/* fields to fill by governance */}

                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'space-between', alignItems: 'center', marginTop: 5 }}>

                            {stateSelected &&
                                <>
                                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <Form.Item
                                            style={{ marginTop: 23, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}
                                            tooltip="Niveau de maîtrise cible"
                                            name="Niveau de maîtrise cible"
                                            label="Niveau de maîtrise cible"
                                            help=""
                                        >
                                            <InputNumber disabled={clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? false : true} defaultValue={stateSelected?.lvl_target_intern} value={current.lvl_target_intern} onChange={toggleTargetIntern} min={1} max={6} />
                                        </Form.Item>

                                        <span style={{ position: 'absolute', bottom: 5, left: '1%', fontWeight: 'bold', fontSize: 12 }}>Niveau de maîtrise actuel : {stateSelected?.lvl_maitrise}</span>
                                    </div>

                                    <Form.Item
                                        tooltip='Quels attendus spécifiques sont à préciser sur le résultat et/ou les moyens ?'
                                        name="Précisions éventuelles"
                                        label="Précisions éventuelles"
                                        rules={[{ required: false, message: 'Quels attendus spécifiques sont à préciser sur le résultat et/ou les moyens ?' }]}
                                        style={{ width: '100%' }}
                                    >
                                        <Input.TextArea disabled={clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? false : true} defaultValue={stateSelected?.descr_attentes_intern} onChange={handleChangeDescrAttentesIntern} showCount autoSize={{ minRows: 2, maxRows: 2 }} maxLength={500} />
                                    </Form.Item>
                                </>
                            }

                            {!stateSelected &&
                                <>
                                    <Form.Item
                                        style={{ marginTop: 23, width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}
                                        tooltip="Niveau de maîtrise cible"
                                        name="Niveau de maîtrise cible"
                                        label="Niveau de maîtrise cible"
                                        help=""
                                    >
                                        <InputNumber disabled={clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? false : true} defaultValue={stateSelected?.lvl_target_intern} value={current.lvl_target_intern} onChange={toggleTargetIntern} min={1} max={clientSelected?.end_amplitude} />
                                    </Form.Item>

                                    <Form.Item
                                        tooltip='Quels attendus spécifiques sont à préciser sur le résultat et/ou les moyens ?'
                                        name="Précisions éventuelles"
                                        label="Précisions éventuelles"
                                        rules={[{ required: false, message: 'Quels attendus spécifiques sont à préciser sur le résultat et/ou les moyens ?' }]}
                                        style={{ width: '80%' }}
                                    >
                                        <Input.TextArea disabled={clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? false : true} defaultValue={stateSelected?.descr_attentes_intern} onChange={handleChangeDescrAttentesIntern} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={500} />
                                    </Form.Item>
                                </>
                            }
                            {stateSelected &&
                                <span className='prio' style={{ width: '25%', maxWidth: '25%', minWidth: '25%', marginLeft: 50, cursor: clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? 'pointer' : 'not-allowed', padding: 10, borderRadius: 10, backgroundColor: 'priority' in current ? current.priority ? '#EEE' : '#FFF' : stateSelected.priority ? '#EEE' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: 'priority' in current ? current.priority ? '#222' : '#DDD' : stateSelected.priority ? '#222' : '#DDD' }} onClick={() => { if (clientSelected && clientSelected.type_hab === 'Risk management' || clientSelected.type_hab === 'Gouvernance') { togglePriority() } }}>{current.priority ? '' : stateSelected.priority ? current.priority ? '' : '' : ''} PRIORITAIRE<FlagFilled style={{ marginLeft: 10, fontSize: 20, color: 'priority' in current ? current.priority ? 'red' : '#DDD' : stateSelected.priority ? 'red' : '#DDD' }} /></span>
                            }
                            {!stateSelected &&
                                <span className='prio' style={{ width: '25%', maxWidth: '25%', minWidth: '25%', marginLeft: 50, cursor: clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? 'pointer' : 'not-allowed', padding: 10, borderRadius: 10, backgroundColor: current.priority ? '#EEE' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: current.priority ? 'red' : '#DDD' }} onClick={() => { if (clientSelected && clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance') { togglePriority() } }}>RISQUE {current.priority ? '' : 'NON'} PRIORITAIRE</span>
                            }

                        </div>

                        {/* 2nd bandeau factuels */}
                        {stateSelected &&
                            <>
                                {/* 2nd bandeau factuels */}
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', backgroundColor: '#FFFEEE', border: '2px solid #FEF375' }}>
                                        <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>

                                                <ScoreDots
                                                    value={stateSelected.crit}
                                                    reverse={true}
                                                />

                                                <div title='Criticité' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', borderRadius: 50 }}>
                                                    <span>Criticité</span>
                                                </div>

                                            </div>
                                        </div>

                                        <span style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Situation actuelle du risque</span>

                                        <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>


                                                <ScoreSliderParametrable
                                                    value={stateSelected.vuln}
                                                    reverse={true}
                                                />
                                                {/* <ScoreDots
                                                    value={stateSelected.vuln}
                                                    reverse={true}
                                                /> */}
                                                <div title='Vulnérabilité' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', borderRadius: 50 }}>
                                                    <span >Vulnérabilité</span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* BANDEAU A 3 COLONNES DU BAS */}
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', backgroundColor: '#FFFEEE', border: '2px solid #FEF375' }}>

                                        <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                            <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>


                                                <ScoreSliderRapportParametrable
                                                    value={stateSelected.rapport}
                                                    reverse={true}
                                                />
                                                {/* <ScoreRapportDots
                                                    value={stateSelected.rapport}
                                                    reverse={true}
                                                /> */}

                                                <div title='Rapport gain/coût' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '70%', borderRadius: 50 }}>
                                                    <span >Rapport gain · coût</span>
                                                </div>

                                            </div>

                                        </div>

                                        <span style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Indicateurs d'optimisation de la maîtrise</span>

                                        <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>

                                                <ScoreDots
                                                    value={stateSelected.prediction}
                                                    reverse={false}
                                                />

                                                <div title='Maîtrise optimale' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', borderRadius: 50 }}>
                                                    <span >Maîtrise optimale</span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {!stateSelected &&

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%', width: '90%', }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%', width: '90%', border: '2px solid #FEF375' }}>
                                    <span style={{ fontSize: 20 }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Indicateurs décisionnels : </span>uniquement disponibles sur un risque déjà suivi 💝</span>
                                </div>
                            </div>
                        }


                    </>
                }
                {/* //////////////////////////////////////////STEP 4 ACTION */}
                {step === 4 &&
                    <>
                        {/* BANDEAU 1 DU HAUT */}
                        {stateSelected &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginBottom: 5 }}>

                                <h2 title={stateSelected.intitule} style={{ margin: 0 }}>{formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>

                                {stateSelected.replaced_by &&
                                    <span>{`Modifié le ${moment(stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by?.firstname}} ${stateSelected.replaced_by?.lastname}`}<img className='avatar-right' src={stateSelected.replaced_by?.avatar} /></span>
                                }
                            </div>
                        }
                        {/* BANDEAU 2 DU HAUT */}
                        {stateSelected &&

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%' }}>

                                <div><span>{`Propriétaire : ${stateSelected.proprietaire?.habilitations.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected.proprietaire?.firstname} ${stateSelected.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected.proprietaire?.avatar} /></div>
                                <div><span>{`Risque créé le ${stateSelected.iterations?.length > 0 ? stateSelected.iterations[stateSelected.iterations.length - 1] : moment(stateSelected.date_creation).format('LLL')} par ${stateSelected?.creator?.firstname} ${stateSelected?.creator?.lastname}`}</span><img className='avatar-right' src={user.avatar} /></div>


                            </div>
                        }

                        {/* DEUX COLONNES EN DESSOUS */}
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%', width: '95%', marginBottom: 20 }}>

                            {/* COLONNE DE GAUCHE */}
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <div className='subcontainercreate' style={{ justifyContent: 'space-evenly' }}>

                                </div>

                                {/* COLONNE DE DROITE */}
                                <div className='subcontainercreate' style={{ justifyContent: 'space-evenly' }}>

                                </div>
                            </div>


                        </div>
                    </>
                }

                <div style={{ position: 'absolute', bottom: '9.5%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: step != 1 && step != 4 ? '55vw' : '44vw' }}>
                    {step != 1 && stateSelected && <div style={{ width: '10vw' }}><Button specif={'light'} texte={'Précédent'} onClick={() => setStep(step - 1)} /></div>}
                    {!saving && !saved && // Object.keys(current).length > 1 &&  deleted
                        <>
                            <div style={{ width: '10vw' }}><Link to={`/suivi${step ? '/' + step : ''}${stateSelected ? '/' + stateSelected._id : ''}`}><Button specif={'cancel'} texte={'Retour'} onClick={() => { setCurrent({}) }}></Button></Link></div>
                            <div style={{ width: '10vw' }}><Link to={'/true'}><Button specif={'cancel'} texte={'Accueil'} onClick={() => { setCurrent({}); dispatch({ type: 'selectstate', state: null }) }}></Button></Link></div>
                        </>
                    }
                    {(Object.keys(current).length > (stateSelected ? 0 : 1)) && (clientSelected?.type_hab === "Contribution métier" || clientSelected?.type_hab === "Risk management") &&
                        stateSelected ?
                        <div style={{ width: '10vw' }}><Button texte={'Enregistrer'} saving={saving} onClick={() => { handleSave() }} /></div>
                        :
                        current.proprietaire &&
                        current.risk &&
                        current.process &&
                        current.intitule &&
                        current.lvl_proba &&
                        current.lvl_impact &&
                        current.lvl_maitrise &&
                        <div style={{ width: '10vw' }}><Button texte={'Enregistrer'} saving={saving} onClick={() => { handleSave() }} /></div>
                    }
                    {step !== 3 && 
                        stateSelected ? 
                        <div style={{ width: '10vw' }}><Button specif={'light'} texte={'Suivant'} onClick={() => setStep(step + 1)} /></div>
                        :
                        current.proprietaire &&
                        current.risk &&
                        current.process &&
                        current.intitule &&
                        <div style={{ width: '10vw' }}><Button specif={'light'} texte={'Suivant'} onClick={() => setStep(step + 1)} /></div>
                    }
                </div>
                {/*LINK CUT OF ENREGISTRER <Link to={stateSelected ? `/suivi${step ? '/'+step : ''}${stateSelected ? '/'+stateSelected._id : ''}` : '/true'}> */}
            </div>

        </>
    )
}
export default ManageRiskState;