import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

//REDUX
import { useDispatch, useSelector } from 'react-redux'

import '../public/styles/informations.css'

import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    message,
    Input,
    Select,
    Form,
    DatePicker,
    InputNumber
} from 'antd';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import Button from './Button';

moment.locale('fr')

const { TextArea } = Input;
const { Option } = Select

const AddInsideState = ({ documentsUploaded, setDocumentsUploaded, audits, auditSelected, stateId, clientUsers, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected, element }) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const successInc = () => {
        message.success('Incident sauvegardé avec succès 👍 ');
    };

    const successAct = () => {
        message.success(`Plan d'action sauvegardé avec succès 👍`);
    };

    const successAudit = () => {
        message.success(`Audit démarré avec succès 👍`);
    };

    const successModifAudit = () => {
        message.success(`Audit modifié avec succès 👍`);
    };

    const successReco = () => {
        message.success(`Analyse enregistrée avec succès 👍`);
    };

    const [current, setCurrent] = useState({})

    const [listAudited, setListAudited] = useState([])

    const [statesAuditActive, setStatesAuditActive] = useState([])

    function onChange(e) {
        setCurrent({ ...current, anonymous: e.target.checked })
    }

    function handleChangeLibelle(e) { setCurrent({ ...current, libelle: e.target.value }) }
    function handleChangePrestataire(e) { setCurrent({ ...current, structure: e.target.value }) }

    function handleChangeObjet(e) { setCurrent({ ...current, objet: e.target.value }) }
    function handleChangeDescr(e) { setCurrent({ ...current, descr_incident: e.target.value }) }

    function handleChangeContent(e) { setCurrent({ ...current, content: e.target.value }) }
    function handleChangeDescrAction(e) { setCurrent({ ...current, descr_action: e.target.value }) }


    function onChangeDateIncident(e) { setCurrent({ ...current, date_incident: e._d }); /*console.log(e._d)*/ }
    function onChangeDateDebut(e) { setCurrent({ ...current, date_debut: e._d }); /*console.log(e._d)*/ }
    function onChangeDateFin(e) { setCurrent({ ...current, date_fin: e._d }); /*console.log(e._d)*/ }

    function onChangeDateClotureReco(e) { setCurrent({ ...current, date_cloture_reco: e._d }); /*console.log(e._d)*/ }
    function onChangeDateCreationReco(e) { setCurrent({ ...current, date_creation_reco: e._d }); /*console.log(e._d)*/ }
    function onChangeDateConstat(e) { setCurrent({ ...current, date_constat: e._d }); /*console.log(e._d)*/ }

    function onChangeDateNotification(e) { setCurrent({ ...current, date_notification: e._d }); /*console.log(e._d)*/ }
    function onChangeDateIntermediaire(e) { setCurrent({ ...current, date_remise_rapport_intermediaire: e._d }); /*console.log(e._d)*/ }
    function onChangeDateValidation(e) { setCurrent({ ...current, date_validation_conclusions_finales: e._d }); /*console.log(e._d)*/ }

    function onChangeResponsableAudit(value) { setCurrent({ ...current, chief_audit: value }); /*console.log(e._d)*/ }

    const onChangeImpact = (value) => { setCurrent({ ...current, impact_ressenti: value }) }
    const onChangeCible = (value) => { setCurrent({ ...current, maitrise_cible: value }) }

    const onChangeMaitriseDeclar = (value) => { setCurrent({ ...current, maitrise_declar: value }) }
    const onChangeMaitriseConstat = (value) => { setCurrent({ ...current, maitrise_constat: value }) }
    const onChangeMaitriseFinale = (value) => { setCurrent({ ...current, maitrise_finale: value }) }

    const handleChangeNatureAudit = (value) => { setCurrent({ ...current, nature: value }) }
    const handleChangeStateConcerne = (value) => { setCurrent({ ...current, state_concerne: value }) }

    const handleChangeNature = (value) => { setCurrent({ ...current, nature_impact: value }) }
    function handleChangeLieu(e) { setCurrent({ ...current, lieu: e.target.value }) }
    function handleChangeService(e) { setCurrent({ ...current, service: e.target.value }) }

    function handleChangeCommentaire(e) { setCurrent({ ...current, commentaire: e.target.value }) }
    const handleChangeServices = (value) => { setCurrent({ ...current, services_associes: value }) }

    function onChangeProprietaire(value) { setCurrent({ ...current, proprietaire: value }) }

    const handleChangeOrigine = (value) => { setCurrent({ ...current, origine: value }) }


    //LISTENERS CHECK

    useEffect(() => {

        if (element === 'audit') {
            const listStatesEnCoursAudit = audits?.filter(e => e?.status === 'active')?.map(f => { return f?.audited_risks }).flat()
            setStatesAuditActive([...new Set(listStatesEnCoursAudit)])
            // console.log('-OKOKOK-', [...new Set(listStatesEnCoursAudit)])
        }

        // Mise à jour de l'état en fonction de user
        if (user) {
            setCurrent(prevState => ({
                ...prevState,
                creator: user._id,
                date_creation: new Date()
            }));
        }

        // Mise à jour de l'état en fonction de element
        if (element === 'reco') {
            let maitrise_declar_from = current?.maitrise_declar ? current?.maitrise_declar :
                auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_declar ?
                    auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_declar :
                    listFollowed.find(e => e._id === stateId)?.lvl_maitrise;

            setCurrent(prevState => ({ ...prevState, maitrise_declar: maitrise_declar_from, state_concerne: stateId, proprietaire: listFollowed?.find(e=>e._id === stateId)?.proprietaire }));
        }

        // Mise à jour de l'état en fonction de documentsUploaded
        setCurrent(prevState => ({
            ...prevState,
            supports: documentsUploaded
        }));
    }, [user, element, documentsUploaded]);


    // useEffect(() => { console.log(listAudited) }, [listAudited])
    // useEffect(() => { console.log(stateId) }, [stateId])
    // useEffect(() => { console.log(auditSelected) }, [auditSelected])
    // useEffect(() => { if (current) { console.log({ current }) } }, [current])
    // useEffect(()=>{if (user) {console.log('user in Informations : ', user)}}, [user])
    // useEffect(()=>{if (clientSelected) {console.log('clientSelected in Informations : ', clientSelected)}}, [clientSelected])
    // useEffect(()=>{if (listFollowed) {console.log('listFollowed in Informations : ', listFollowed)}}, [listFollowed])
    // useEffect(()=>{if (listByRisks) {console.log('listByRisks in Informations : ', listByRisks)}}, [listByRisks])
    // useEffect(()=>{if (listByProcesses) {console.log('listByProcesses in Informations : ', listByProcesses)}}, [listByProcesses])
    // useEffect(()=>{if (stateSelected) {console.log('stateSelected in Informations : ', stateSelected)}}, [stateSelected])


    //////////////// PARTIE AUDIT
    const addOrRemove = (item) => {
        let temp = [...listAudited]

        if (temp.find(e => e._id === item._id)) {
            //console.log({alreadyExist: temp.find(e=>e._id === item._id)})
            temp.splice(temp.findIndex(e => e._id === item._id), 1)
        } else {
            //console.log({alreadyExist: temp.find(e=>e._id === item._id)})
            temp.push(item)
        }

        setListAudited(temp)
    }

    const selectAll = () => {
        if (listAudited.length > 0) {
            setListAudited([])
        } else {
            setListAudited(listFollowed)
        }
    }


    /////////////////////////////

    const handleSave = async () => {

        switch (element) {

            case 'incident':
                let incidentToPush = { ...current, date_creation: new Date(), creator: user?._id }
                const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/new-incident`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ incidentToPush: incidentToPush, stateId: stateSelected?._id })
                })
                const json = await data.json()

                if (json.result) {
                    await successInc()
                    setCurrent({})
                    navigate(-1)
                }
                break
            case 'action':
                let actionToPush = { ...current, date_creation: new Date(), creator: user?._id }
                const dataAct = await fetch(`${process.env.REACT_APP_BACK_END}/states/new-action`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ actionToPush: actionToPush, stateId: stateSelected?._id, creator: user?._id })
                })
                const jsonAct = await dataAct.json()

                if (jsonAct.result) {
                    await successAct()
                    setCurrent({})
                    navigate(-1)
                }
                break
            case 'audit':
                let auditToCreate;
                if (auditSelected) {
                    delete current.creator
                    delete current.date_creation
                    delete current.supports
                    // console.log({ current })
                    auditToCreate = { ...current, lastModifier: user?._id }
                    const dataUpdateAudit = await fetch(`${process.env.REACT_APP_BACK_END}/audits/update-audit/${auditSelected?._id}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ auditToCreate: auditToCreate })
                    })
                    const jsonUpdateAudit = await dataUpdateAudit.json()

                    // console.log(jsonUpdateAudit.savedAudit)

                    if (jsonUpdateAudit.result) {
                        // console.log('COUCOUCOUCOUCOUCOUCOU ', jsonUpdateAudit.savedAudit)
                        await successModifAudit()
                        setCurrent({})
                        dispatch({ type: 'selectAudit', audit: {} })
                        navigate(-1)
                    }
                } else {
                    auditToCreate = { ...current, listAudited: listAudited.map((e) => { return e._id }), date_demarrage: new Date(), creator: user?._id, client: clientSelected._id }
                    const dataAudit = await fetch(`${process.env.REACT_APP_BACK_END}/audits/start-audit`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ auditToCreate: auditToCreate })
                    })
                    const jsonAudit = await dataAudit.json()

                    // console.log(jsonAudit.savedAudit)

                    if (jsonAudit.result) {
                        await successAudit()
                        setCurrent({})
                        navigate(-1)
                    }
                }
                break
            case 'reco':
                let recoToSave = { ...current, creator: user?._id, client: clientSelected._id, auditId: auditSelected._id }
                const dataReco = await fetch(`${process.env.REACT_APP_BACK_END}/audits/create-reco`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ recoToSave: recoToSave })
                })
                const jsonReco = await dataReco.json()


                if (jsonReco.result) {
                    // console.log(' HERE HERE HEREE ', jsonReco.recoSaved)
                    await successReco()
                    setCurrent({})
                    navigate(-1)
                }
                break
        }

    }

    return (
        <>
            <div className='containersuivi' style={{ justifyContent: element === 'audit' || element === 'reco' ? 'flex-start' : 'center', alignItems: 'flex-start', padding: element === 'audit' || element === 'reco' ? '5%' : '10%', overflowY: element === 'audit' || element === 'reco' ? 'auto' : 'none' }}>

                <h2 style={{ textAlign: 'justify', fontSize: 15, marginBottom: 20 }}>
                    {element === 'incident' ?
                        `Renseignez toutes les informations concernant l'incident que vous souhaitez déclarer. Cet incident peut être déclaré anonymement, c'est à dire que votre identité ne sera stockée nulle part donc accessible à aucun tiers.`
                        :
                        element === 'action' ?
                            `Décrivez le plan d'action envisagé : temporalité, services associés, cible, points de vigilance, résultats attendus, gains estimés, etc.`
                            :
                            ``
                    }
                </h2>

                {element === 'incident' &&
                    <>
                        {/* Objet de l'add */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Objet descriptif global"
                            name="Objet"
                            label="Objet"
                            hasFeedback
                            validateStatus={current.objet ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                    whitespace: true
                                },
                            ]}
                        >
                            <Input value={current.objet} onChange={handleChangeObjet} />
                        </Form.Item>


                        {/* date incident */}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Form.Item style={{}} tooltip="Date de l'incident" name="Date de l'incident" label="Date de l'incident" hasFeedback validateStatus={current.date_incident ? "success" : ''} >
                                <DatePicker value={current.date_incident} onChange={onChangeDateIncident} />
                            </Form.Item>

                            {/* Impact ressenti */}
                            <Form.Item
                                style={{}}
                                tooltip="Impact ressenti"
                                name="Impact ressenti"
                                label="Impact ressenti"
                                help=""
                            >
                                <InputNumber value={current.impact_ressenti} onChange={onChangeImpact} min={clientSelected?.start_amplitude} max={clientSelected?.end_amplitude} />
                            </Form.Item>
                        </div>

                        {/* Nature d'impact */}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <span style={{ marginTop: '1%', width: '17%' }}>Nature(s) d'impact :</span>
                            <Select maxTagCount={2} mode="tags" style={{ width: '83%', marginBottom: 20 }} placeholder="Sélectionner une ou plusieurs nature(s) d'impact" onChange={handleChangeNature}>
                                {["Financier", "Organisationnel", "Réputationnel", "Juridique", "Humain"].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </div>

                        {/* Lieu et service */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Lieu de l'incident"
                            name="Lieu de l'incident"
                            label="Lieu de l'incident"
                            hasFeedback
                            validateStatus={current.lieu ? "success" : ''}
                            help=""
                        >
                            <Input value={current.lieu} onChange={handleChangeLieu} />
                        </Form.Item>

                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Service(s) concerné(s)"
                            name="Service(s) concerné(s)"
                            label="Service(s) concerné(s)"
                            hasFeedback
                            validateStatus={current.service ? "success" : ''}
                            help=""
                        >
                            <Input value={current.service} onChange={handleChangeService} />
                        </Form.Item>

                        {/* INCIDENT - 
    supports_incident: Array */}

                        {/* Descriptif */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip={`Décrire l'incident relevé (date, faits, incidences, etc.)`}
                            name={`Description de l'incident`}
                            label={`Description de l'incident (date, lieu, services, faits, incidences avérées ou potentielles`}
                            rules={[{ required: true, message: `Décrire l'incident relevé (date, faits, incidences, etc.)` }]}
                        >
                            <Input.TextArea value={current.descr_incident} onChange={handleChangeDescr} showCount autoSize={{ minRows: 3, maxRows: 3 }} maxLength={2000} />
                        </Form.Item>

                        {/* Commentaires éventuels */}
                        {/* <Form.Item
                                    style={{ width: '100%' }}
                                    tooltip={`Commentaires éventuels`}
                                    name={`Commentaires éventuels`}
                                    label={`Commentaires éventuels`}
                                    rules={[{ required: true, message: `Commentaires éventuels susceptibles d'aider à la compréhension et la prise de décision`}]}
                                >
                                    <Input.TextArea value={current.commentaire} onChange={handleChangeCommentaire} showCount autoSize={{ minRows: 2, maxRows: 2 }} maxLength={300} />
                                </Form.Item> */}

                        {/* Save */}
                        <div style={{ alignSelf: 'center', display: 'flex', width: '60%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button specif={'cancel'} texte={'Retour'} styleAdded={{ width: '30%' }} onClick={() => { setCurrent({}); navigate(-1) }}></Button>
                            <Button texte='Valider' onClick={() => { handleSave() }} styleAdded={{ width: '30%' }} />

                            <Checkbox onChange={onChange}>Conserver l'anonymat</Checkbox>

                        </div>
                    </>
                }
                {element === 'action' &&
                    <>
                        {/* Objet de l'add */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Objet descriptif global"
                            name="Objet"
                            label="Objet"
                            hasFeedback
                            validateStatus={current.objet ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                    whitespace: true
                                },
                            ]}
                        >
                            <Input value={current.objet} onChange={handleChangeObjet} />
                        </Form.Item>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* date début */}

                            <Form.Item style={{}} tooltip="Date de début" name="Date de début" label="Date de début" hasFeedback validateStatus={current.date_debut ? "success" : ''} >
                                <DatePicker value={current.date_debut} onChange={onChangeDateDebut} />
                            </Form.Item>

                            {/* date fin */}

                            <Form.Item style={{}} tooltip="Date de fin" name="Date de fin" label="Date de fin" hasFeedback validateStatus={current.date_fin ? "success" : ''} >
                                <DatePicker value={current.date_fin} onChange={onChangeDateFin} />
                            </Form.Item>

                            {/* Maitrise cible */}
                            <Form.Item
                                style={{}}
                                tooltip="Maîtrise cible"
                                name="Maîtrise cible"
                                label="Maîtrise cible"
                                help=""
                            >
                                <InputNumber value={current.maitrise_cible} onChange={onChangeCible} min={clientSelected?.start_amplitude} max={clientSelected?.end_amplitude} />
                            </Form.Item>

                        </div>

                        {/* Origine */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Sélectionner l'origine"
                            name="Origine"
                            label="Origine"
                            hasFeedback
                            validateStatus={current.origine ? "success" : ''}
                            help=""
                        >
                            <Select
                                showSearch
                                placeholder="Sélectionner ou rechercher..."
                                optionFilterProp="children"
                                onChange={handleChangeOrigine}
                            >
                                {
                                    ["Gouvernance", "Audit", "Contrôle"].map((e) => {
                                        return <Option key={e}>{e}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginBottom: 10 }}>
                            <span style={{ width: '20%', alignSelf: 'left', marginBottom: 10 }}>Service(s) associé(s) :</span>
                            <Select maxTagCount={3} mode="tags" style={{ width: '80%' }} placeholder="Entrez un ou plusieurs service(s)" onChange={handleChangeServices} tokenSeparators={[',', ';', ':', '-']}>
                                {[].map((e, i) => { return <Option key={e}>{e}</Option> })}
                            </Select>
                        </div>


                        {/* ACTION - 
    date_closure: String,
    supports_action: Array */}


                        {/* Descriptif */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip={`Décrire le plan d'action (moyens, ressources, arbitrages, etc.)`}
                            name={`Description du plan d'action`}
                            label={`Description du plan d'action (moyens, ressources, arbitrages, services mobilisés, résultats attendus, etc.)`}
                            rules={[{ required: true, message: `Décrire le plan d'action (moyens, ressources, arbitrages, etc.)` }]}
                        >
                            <Input.TextArea value={current.descr_incident} onChange={handleChangeDescr} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={2000} />
                        </Form.Item>

                        {/* Commentaires éventuels */}
                        {/* <Form.Item
                                    style={{ width: '100%' }}
                                    tooltip={`Commentaires éventuels`}
                                    name={`Commentaires éventuels`}
                                    label={`Commentaires éventuels`}
                                    rules={[{ required: true, message: `Commentaires éventuels susceptibles d'aider à la compréhension et la prise de décision`}]}
                                >
                                    <Input.TextArea value={current.commentaire} onChange={handleChangeCommentaire} showCount autoSize={{ minRows: 3, maxRows: 3 }} maxLength={300} />
                                </Form.Item> */}

                        {/* Save */}
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            <Button specif={'cancel'} texte={'Retour'} styleAdded={{ width: '25%', marginRight: 30 }} onClick={() => { setCurrent({}); navigate(-1) }}></Button>
                            <Button texte='Valider' onClick={() => { handleSave() }} styleAdded={{ width: '25%' }} />
                        </div>
                    </>
                }

                {/* //////////////////////////// AUDIT                 */}

                {element === 'audit' &&
                    <>
                        <h3>
                            <h3>Avant le démarrage d'un audit, assurez-vous d'avoir enregistré le profil de l'auditeur responsable de l'audit pour pouvoir lui attribuer l'audit.</h3>
                            <h3>Il appartient à vos effectifs rapportés dans la mesure où c'est vous qui avez le contrôle sur son habilitation.</h3>
                            <h3>Les risques faisant l'objet d'un audit non clôturé à date n'apparaissent pas dans la liste des risques auditionnables.</h3>
                        </h3>

                        <h2 style={{ margin: 30 }}>
                            1 · Modalités de l'audit :
                        </h2>

                        {/* libelle */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Libelle clair de l'audit (type, périmètre, dates)"
                            name="Libelle"
                            label="Libelle"
                            hasFeedback
                            validateStatus={current.libelle ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                    whitespace: true
                                },
                            ]}
                        >
                            <Input defaultValue={auditSelected?.libelle ? auditSelected?.libelle : ''} value={current?.libelle} onChange={handleChangeLibelle} />
                        </Form.Item>

                        {/* Nature d'audit */}

                        <Form.Item
                            style={{ width: '100%', maxWidth: '90%', flexWrap: 'nowrap' }}
                            tooltip="Sélectionner la nature de l'audit"
                            name="Nature d'audit"
                            label="Nature d'audit"
                            hasFeedback
                            validateStatus={current.nature ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                defaultValue={auditSelected?.nature ? auditSelected?.nature : ''}
                                optionFilterProp="children"
                                onChange={handleChangeNatureAudit}
                            >
                                {['Audit interne', 'Audit externe', 'Contrôle interne', 'Contrôle externe'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </Form.Item>

                        {/* Prestataire */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Raison sociale du prestataire mandaté, le cas échéant"
                            name="Prestataire"
                            label="Prestataire"
                            hasFeedback
                            validateStatus={current.structure ? "success" : ''}
                            help=""
                        >
                            <Input defaultValue={auditSelected?.structure ? auditSelected?.structure : ''} value={current.structure} onChange={handleChangePrestataire} />
                        </Form.Item>

                        {clientUsers &&
                            <Form.Item
                                style={{ width: '100%', maxWidth: '90%', flexWrap: 'nowrap' }}
                                tooltip="Sélectionner la ou le responsable de l'audit"
                                name="Responsable de l'audit"
                                label="Responsable de l'audit"
                                hasFeedback
                                validateStatus={current.chief_audit ? "success" : ''}
                                help=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Désolé, obligatoire 😁',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    defaultValue={auditSelected?.chief_audit ? auditSelected?.chief_audit : ''}
                                    optionFilterProp="children"
                                    onChange={onChangeResponsableAudit}
                                >
                                    {clientUsers.length > 0 &&
                                        clientUsers.filter((e) => e._id != "62699350bf7c7ded37e4dfdb").filter((f) => f.habilitations.find(e => e.client_hab === clientSelected._id).type_hab === 'Audit').map((e, j) => {
                                            return <Option key={j} value={e._id}><img src={e.avatar} style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }} />{e.firstname} {e.lastname} - {e.role.length > 28 ? e.role.slice(0, 28) + '...' : e.role}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        }

                        <Form.Item style={{}} tooltip="Date de notification" name="Date de notification" label="Date de notification" hasFeedback validateStatus={current.date_notification ? "success" : ''} >
                            <DatePicker defaultValue={auditSelected?.date_notification ? auditSelected?.date_notification : ''} value={current.date_notification} onChange={onChangeDateNotification} />
                        </Form.Item>

                        <Form.Item style={{}} tooltip="Date de remise du rapport intermédiaire" name="Date de remise du rapport intermédiaire" label="Date de remise du rapport intermédiaire" hasFeedback validateStatus={current.date_remise_rapport_intermediaire ? "success" : ''} >
                            <DatePicker defaultValue={auditSelected?.date_remise_rapport_intermediaire ? auditSelected?.date_remise_rapport_intermediaire : ''} value={current.date_remise_rapport_intermediaire} onChange={onChangeDateIntermediaire} />
                        </Form.Item>

                        <Form.Item style={{}} tooltip="Date de validation des conclusions finales" name="Date de validation des conclusions finales" label="Date de validation des conclusions finales" hasFeedback validateStatus={current.date_validation_conclusions_finales ? "success" : ''} >
                            <DatePicker defaultValue={auditSelected?.date_validation_conclusions_finales ? auditSelected?.date_validation_conclusions_finales : ''} value={current.date_validation_conclusions_finales} onChange={onChangeDateValidation} />
                        </Form.Item>

                        <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} defaultChecked={auditSelected?.externalise ? true : false} checked={current?.externalise} onChange={() => { setCurrent({ ...current, externalise: current?.externalise ? false : true }) }}>Audit externalisé</Checkbox>

                        <h2 style={{ margin: 30 }}>
                            2 · Risques audités :
                        </h2>

                        {!auditSelected && <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} checked={listAudited.length > 0} onChange={() => { selectAll() }}>Tout sélectionner</Checkbox>}


                        {/* list.map */}
                        {listByRisks.length > 0 &&

                            // listFollowed.map((risk, i)=>{
                            // return (

                            // <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            //     <Checkbox onChange={()=>{ addOrRemove(risk) }}></Checkbox>
                            //     <p style={{margin: '5px 5px 5px 15px'}} className='risks' >{risk.intitule}</p>
                            // </div>
                            // )

                            listByRisks.map((e) => {
                                let listRisks = e.family.risks.sort((a, b) => a.code - b.code).map((f) => {
                                    let listStates = f.states.filter((st) => { return !statesAuditActive.some(m => m === st?._id) })?.sort((a, b) => a.code - b.code).map((g) => {
                                        // console.log(g.proprietaire._id, ' - ', user._id)
                                        return (
                                            <div key={g._id} style={{ marginLeft: 50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <Checkbox checked={auditSelected?.audited_risks?.length > 0 ? auditSelected?.audited_risks?.find(e => e === g?._id) : listAudited?.find(e => e?._id === g?._id)} onChange={() => { addOrRemove(g) }}></Checkbox>
                                                <span style={{ cursor: 'pointer', margin: '5px 5px 5px 15px' }} onClick={() => { addOrRemove(g) }}>{f.code} · {g.intitule}</span>
                                            </div>
                                        )
                                    })
                                    return (
                                        <div className='card'>
                                            {clientSelected.level_more && <span>{f.code} · {f.libelle}</span>}
                                            {listStates}
                                        </div>
                                    )
                                })
                                return (
                                    <Form>
                                        <Form.Item>
                                            <div className='card'>
                                                <span className='tag-top'>{e.family.code} · {e.family.libelle}</span>
                                                {listRisks}
                                            </div>
                                        </Form.Item>
                                    </Form>
                                )

                            })

                        }
                        <div style={{ display: 'flex', position: 'absolute', bottom: '8%', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                            <Button specif={'cancel'} texte={'Annuler'} styleAdded={{ width: '15%', marginRight: 30 }} onClick={() => { setCurrent({}); dispatch({ type: 'selectAudit', audit: {} }); setListAudited([]); navigate(-1) }}></Button>
                            {((current.chief_audit && listAudited.length > 0) || auditSelected) && <Button texte={auditSelected ? 'Enregistrer' : 'Débuter'} onClick={() => { handleSave() }} styleAdded={{ width: '15%' }} />}
                        </div>
                    </>
                }

                {/* //////////////////////////// RECO                 */}

                {element === 'reco' &&
                    <>
                        <h3 style={{ marginBottom: 30 }}>
                            Saisissez les analyses menées et les données relatives à l'étude en cours sur le risque considéré.
                        </h3>

                        <h2 style={{}}>
                            1 · Identification :
                        </h2>

                        {/* Risque concerne */}

                        <Form.Item
                            style={{ width: '100%', maxWidth: '90%', flexWrap: 'nowrap' }}
                            tooltip="Sélectionner le risque concerné"
                            name="Risque concerné"
                            label="Risque concerné"
                            hasFeedback
                            validateStatus={current.state_concerne ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                defaultValue={listFollowed?.find(e => e._id === stateId)?.intitule}
                                optionFilterProp="children"
                                onChange={handleChangeStateConcerne}
                            >
                                {auditSelected?.audited_risks?.map((e, i) => { return <Option key={e}>{listFollowed?.find(f => f._id === e)?.intitule}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </Form.Item>

                        {/* Proprietaire */}

                        {clientUsers &&
                            <Form.Item
                                style={{ width: '60%', maxWidth: '60%', flexWrap: 'nowrap' }}
                                tooltip="Sélectionner un propriétaire à qui affecter ce risque"
                                name="Propriétaire"
                                label="Propriétaire"
                                hasFeedback
                                validateStatus={current.proprietaire ? "success" : ''}
                                help=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Désolé, obligatoire 😁',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    defaultValue={`${listFollowed?.find(e => e._id === stateId)?.proprietaire?.firstname} ${listFollowed?.find(e => e._id === stateId)?.proprietaire?.lastname} - ${listFollowed?.find(e => e._id === stateId)?.proprietaire?.role}`}
                                    optionFilterProp="children"
                                    onChange={onChangeProprietaire}
                                >
                                    {clientUsers.length > 0 &&
                                        clientUsers.filter((e) => e._id != "62699350bf7c7ded37e4dfdb").map((e, j) => {
                                            return <Option key={j} value={e._id}><img src={e.avatar} style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }} />{e.firstname} {e.lastname} - {e.role.length > 28 ? e.role.slice(0, 28) + '...' : e.role}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        }

                        {/* Services associés */}
                        <div style={{width: '80%'}}>
                            <span style={{ width: '20%', maxWidth: '80%', alignSelf: 'left', marginBottom: 10 }}>Service(s) associé(s) : </span>
                            <Select maxTagCount={2} mode="tags" defaultValue={listFollowed?.find(e => e._id === stateId)?.services_associes} style={{ width: '50%', marginBottom: 20 }} placeholder="Entrez un ou plusieurs service(s)" onChange={handleChangeServices} tokenSeparators={[',', ';', ':', '-']}>
                                {[].map((e, i) => { return <Option key={e}>{e}</Option> })}
                            </Select>
                        </div>
                        {/* Objet */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Objet de l'analyse et des recommandations afférentes"
                            name="Objet"
                            label="Objet"
                            hasFeedback
                            validateStatus={current.objet ? "success" : ''}
                            help=""
                        >
                            <Input defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.objet ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.objet : ''} value={current.objet} onChange={handleChangeObjet} />
                        </Form.Item>

                        <h2 style={{}}>
                            2 · Modalités :
                        </h2>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} checked={current.hasOwnProperty('constat_sur_pieces') ? current.constat_sur_pieces : auditSelected?.reco?.find(e => e.state_concerne === stateId)?.constat_sur_pieces ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.constat_sur_pieces : false} onChange={() => { setCurrent({ ...current, constat_sur_pieces: current.constat_sur_pieces ? false : true }) }}>Constat sur pièces</Checkbox>
                                <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} checked={current.hasOwnProperty('constat_sur_place') ? current.constat_sur_place : auditSelected?.reco?.find(e => e.state_concerne === stateId)?.constat_sur_place ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.constat_sur_place : false} onChange={() => { setCurrent({ ...current, constat_sur_place: current.constat_sur_place ? false : true }) }}>Constat sur place</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} checked={current.hasOwnProperty('phase_test') ? current.phase_test : auditSelected?.reco?.find(e => e.state_concerne === stateId)?.phase_test ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.phase_test : false} onChange={() => { setCurrent({ ...current, phase_test: current.phase_test ? false : true }) }}>Phase de test</Checkbox>
                                <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} checked={current.hasOwnProperty('plan_action_necessaire') ? current.plan_action_necessaire : auditSelected?.reco?.find(e => e.state_concerne === stateId)?.plan_action_necessaire ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.plan_action_necessaire : false} onChange={() => { setCurrent({ ...current, plan_action_necessaire: current.plan_action_necessaire ? false : true }) }}>Mesures correctives attendues</Checkbox>
                            </div>
                        </div>

                        <h2 style={{}}>
                            3 · Analyses :
                        </h2>

                        <div style={{ alignSelf: 'center', marginBottom: 20, color: '#CCC' }}>----- Etat -----</div>

                        <div style={{ marginTop: 10, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Form.Item
                                style={{}}
                                tooltip="Maîtrise déclarée par les équipes métiers lors de la saisie ou de la modification du risque concerné"
                                name="Maîtrise déclarée"
                                label="Maîtrise déclarée"
                                help=""
                            >
                                <InputNumber defaultValue={current?.maitrise_declar ? current?.maitrise_declar : auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_declar ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_declar : listFollowed.find(e => e._id === stateId)?.lvl_maitrise} onChange={onChangeMaitriseDeclar} min={clientSelected?.start_amplitude} max={clientSelected?.end_amplitude} />
                            </Form.Item>

                            <Form.Item style={{}} tooltip="Date de démarrage" name="Date de démarrage" label="Date de démarrage" hasFeedback validateStatus={current.date_creation_reco ? "success" : ''} >
                                <DatePicker defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.date_creation_reco ? moment(auditSelected?.reco?.find(e => e.state_concerne === stateId)?.date_creation_reco) : ''} value={current.date_creation_reco} onChange={onChangeDateCreationReco} />
                            </Form.Item>
                        </div>

                        <div style={{ alignSelf: 'center', marginBottom: 20, color: '#CCC' }}>----- Constat -----</div>

                        {/* Content */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Analyses menées"
                            name="Résultats et analyses"
                            label="Résultats et analyses"
                            hasFeedback
                            validateStatus={current.content ? "success" : ''}
                            help=""
                        >
                            <Input.TextArea defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.content ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.content : ''} value={current.content} onChange={handleChangeContent} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={3000} />
                        </Form.Item>

                        <div style={{ marginTop: 10, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Form.Item
                                style={{}}
                                tooltip="Maîtrise constatée après analyse"
                                name="Maîtrise constatée"
                                label="Maîtrise constatée"
                                help=""
                            >
                                <InputNumber defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_constat ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_constat : ''} value={current?.maitrise_constat ? current?.maitrise_constat : auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_constat} onChange={onChangeMaitriseConstat} min={clientSelected?.start_amplitude} max={clientSelected?.end_amplitude} />
                            </Form.Item>
                            <Form.Item style={{}} tooltip="Date de constat" name="Date de constat" label="Date de constat" hasFeedback validateStatus={current.date_constat ? "success" : ''} >
                                <DatePicker defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.date_constat ? moment(auditSelected?.reco?.find(e => e.state_concerne === stateId)?.date_constat) : ''} value={current.date_constat} onChange={onChangeDateConstat} />
                            </Form.Item>

                        </div>
                        <div style={{ alignSelf: 'center', marginBottom: 20, color: '#CCC' }}>----- Recommandation -----</div>

                        {/* Descr_action */}
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Etayage des mesures correctives à apporter"
                            name="Mesures correctives"
                            label="Mesures correctives"
                            hasFeedback
                            validateStatus={current.descr_action ? "success" : ''}
                            help=""
                        >
                            <Input.TextArea defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.descr_action ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.descr_action : ''} value={current.descr_action} onChange={handleChangeDescrAction} showCount autoSize={{ minRows: 5, maxRows: 5 }} maxLength={3000} />
                        </Form.Item>

                        {/* maitrises */}

                        {current.phase_test &&
                            <div style={{ marginTop: 10, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Form.Item
                                    style={{}}
                                    tooltip="Maîtrise constatée finale suite à une phase de test"
                                    name="Maîtrise constatée finale"
                                    label="Maîtrise constatée finale"
                                    help=""
                                >
                                    <InputNumber defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_finale ? auditSelected?.reco?.find(e => e.state_concerne === stateId)?.maitrise_finale : ''} value={current.maitrise_finale} onChange={onChangeMaitriseFinale} min={clientSelected?.start_amplitude} max={clientSelected?.end_amplitude} />
                                </Form.Item>

                                <Form.Item style={{}} tooltip="Date de clôture" name="Date de clôture" label="Date de clôture" hasFeedback validateStatus={current.date_cloture_reco ? "success" : ''} >
                                    <DatePicker defaultValue={auditSelected?.reco?.find(e => e.state_concerne === stateId)?.date_cloture_reco ? moment(auditSelected?.reco?.find(e => e.state_concerne === stateId)?.date_cloture_reco) : ''} value={current.date_cloture_reco} onChange={onChangeDateClotureReco} />
                                </Form.Item>

                            </div>
                        }
                        <div style={{ display: 'flex', position: 'absolute', bottom: '8%', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                            <Button specif={'cancel'} texte={'Annuler'} styleAdded={{ width: '15%', marginRight: 30 }} onClick={() => { setCurrent({}); dispatch({ type: 'selectAudit', audit: {} }); dispatch({ type: 'changeStateId', stateId: null }); navigate(-1) }}></Button>
                            {current.state_concerne && <Button texte='Sauvegarder' onClick={() => { handleSave(); dispatch({ type: 'selectAudit', audit: {} }); dispatch({ type: 'changeStateId', stateId: null }) }} styleAdded={{ width: '15%' }} />}
                        </div>

                    </>
                }

            </div>

        </>
    )
}
export default AddInsideState;