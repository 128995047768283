import React, { useState, useEffect } from 'react'
import '../public/styles/carto.css'
import { Link } from 'react-router-dom';

import RadianGraph from './RadianGraph';
import RadianGraphMacro from './RadianGraphMacro';

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

import { short, shortparam } from '../helpers'

//ant_____________________
import {

    Select, Slider, Form

} from 'antd';
import { CaretLeftFilled, CaretRightFilled, EyeOutlined, RadarChartOutlined, SwapOutlined, ZoomInOutlined } from '@ant-design/icons';


const Carto = ({ clientUsers, setStateSelected, toggleNum, clientSelected, listFollowed, listByRisks, listByProcesses }) => {

    const [form] = Form.useForm()

    //antd
    const { Option } = Select;

    //redux
    const dispatch = useDispatch()

    const [classicMode, setClassicMode] = useState(false) //on SUpport, Realisation and Governance or on processes themselves
    const [showProp, setShowProp] = useState(false) // show proprietaire or not
    const [showFull, setShowFull] = useState(false) // show function of proprietaire or not
    const [showVuln, setShowVuln] = useState(false) // show vulnerability or not
    const [showVulnTop, setShowVulnTop] = useState(false) // show vulnerability or not
    const [showColorsMean, setShowColorsMean] = useState(false) // show alternative colors (heatmap) bu mean
    const [showColorsHigher, setShowColorsHigher] = useState(false) // show alternative colors (heatmap) by highest
    const [showStates, setShowStates] = useState(false) // show alternative colors (heatmap) by highest
    const [proprio, setProprio] = useState('') // proprietaire à mettre en lumiere

    const [sens, setSens] = useState(false)
    // const [showColorsLower, setShowColorsLower] = useState(false) // show alternative colors (heatmap) by highest
    // useEffect(() => {
    //     console.log({ proprio })
    // }, [proprio])


    // console.log({ listByRisks }, { listByProcesses }, { listFollowed }, { clientUsers })

    // console.log('list processes : ', listByProcesses.map((e)=>{return e.family.processes.map((f)=>{return f.libelle})}).flat())
    const listProcesses = listByProcesses.map((e) => { return e.family.processes.map((f) => { return f.libelle }) }).flat()
    // console.log({ listProcesses })

    // let fondColumn = (numberOfColumns) => {
    //     let array = []
    //     for(let i=1;i<numberOfColumns+1;i++){
    //         array.push(<div style={{textAlign: 'center', fontWeight: 'bold', backgroundColor: 'lightcoral', display: 'flex', justifyContent: 'center', alignItems: 'center', gridRow: 1, gridColumn: i+1}}>{listByRisks[i-1].family.libelle}</div>)            
    //     }
    //     return array
    // }

    //function fond
    let fond = (numberOfColumns, numberOfRows) => {
        let array = []
        for (let i = 1; i < numberOfRows + 1; i++) {
            for (let j = 1; j < numberOfColumns + 1; j++) {
                // console.log(i,j)
                array.push(<div style={{ borderRadius: 7, backgroundColor: '#FAFAFA', display: 'flex', justifyContent: 'center', alignItems: 'center', gridRow: i + 1, gridColumn: j + 1 }}></div>)
            }
        }
        return array
    }

    //function en tete columns
    let columnsHeader = (numberOfColumns) => {
        let array = []
        for (let i = 1; i < numberOfColumns + 1; i++) {
            array.push(<div style={{ padding: 3, fontSize: 12, borderRadius: 7, backgroundColor: '#a2d2ff', textAlign: 'center', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', gridRow: 1, gridColumn: i + 1 }}>{sens ? classicMode ? listByProcesses[i - 1].family.libelle : listProcesses[i - 1] : listByRisks[i - 1].family.libelle}</div>)
        }
        return array
    }

    let rowsHeader = (numberOfRows) => {
        let array = []
        for (let i = 1; i < numberOfRows + 1; i++) {
            array.push(<div style={{ padding: 3, fontSize: 12, borderRadius: 7, backgroundColor: '#ffafcc', textAlign: 'center', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', gridRow: i + 1, gridColumn: 1 }}>{sens ? listByRisks[i - 1].family.libelle : classicMode ? listByProcesses[i - 1].family.libelle : listProcesses[i - 1]}</div>)
        }
        return array
    }

    let determineColor = (score) => {
        if (score < -7) {
            return '#0A61E6'
        } else if (score < -4) {
            return '#8ED8F2'
        } else if (score < -1) {
            return '#DAF0F6'
        } else if (score < 1) {
            return '#51C216'
        } else if (score < 4) {
            return '#FAD02B'
        } else if (score < 7) {
            return '#F48C06'
        } else { return '#F73305' }
    }

    // let determineQuartiles = (array) => {
    //     let lowerMean = array.reduce((a,b)=>{return (b<a) ? b : a})
    //     console.log({lowerMean})
    //     let higherMean = array.reduce((a,b)=>{return (b>a) ? b : a})
    //     console.log({higherMean})
    //     let amplitudeMeans = higherMean-lowerMean
    //     console.log({amplitudeMeans})
    //     let quartile = amplitudeMeans/4 
    //     console.log({quartile})
    //     let limq1 = lowerMean + quartile
    //     let limq2 = limq1 + quartile 
    //     let limq3 = limq2 + quartile         
    //     let limq4 = limq3 + quartile         
    //     console.log(quartile, limq1, limq2, limq3, limq4)
    //     let limites = {q1: limq1, q2: limq2, q3: limq3, q4: limq4}
    //     return limites
    // }

    let determinationCellules = (numberOfRows, numberOfColumns) => {
        let array = []
        let means = []
        let highers = []
        for (let i = 1; i < numberOfRows + 1; i++) {
            for (let j = 1; j < numberOfColumns + 1; j++) {

                let crossIJ = []
                let proprietaires = []
                let color = '#cdb4db'
                let higher = 0
                let mean = 0

                classicMode ?
                    crossIJ = listFollowed.filter((e) => {
                        return sens ?
                            e.process.process_family === listByProcesses[j - 1].family.libelle && e.risk.family.libelle === listByRisks[i - 1].family.libelle
                            :
                            e.process.process_family === listByProcesses[i - 1].family.libelle && e.risk.family.libelle === listByRisks[j - 1].family.libelle
                    })
                    :
                    crossIJ = listFollowed.filter((e) => {
                        return sens ?
                            e.process.libelle === listProcesses[j - 1] && e.risk.family.libelle === listByRisks[i - 1].family.libelle
                            :
                            e.process.libelle === listProcesses[i - 1] && e.risk.family.libelle === listByRisks[j - 1].family.libelle
                    })


                //calcul moyenne
                if (crossIJ.length > 0) {
                    let arr = crossIJ.map(e => e.rapport)
                    mean = (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(1)
                    means.push(Number(mean))
                }

                //calcul plus haute
                if (crossIJ.length > 0) {
                    let arr = crossIJ.map(e => e.rapport)
                    higher = arr.reduce((a, b) => {
                        return (b > a) ? b : a
                    }).toFixed(1)
                    highers.push(Number(higher))
                    let amplitudeHighers = highers.reduce((a, b) => { return (b > a) ? b : a }) - highers.reduce((a, b) => { return (b < a) ? b : a })
                    // console.log({amplitudeHighers}) 
                }


                if (crossIJ.length > 0) {
                    let proprietairesTemp = []
                    crossIJ.forEach((el, i) => {
                        // console.log('comparaison : ', [...proprietairesTemp].findIndex(e=>e.proprietaire_id === el.proprietaire._id), proprietairesTemp)
                        if ([...proprietairesTemp].findIndex(e => e.proprietaire._id === el.proprietaire._id) < 0) {
                            proprietairesTemp.push(el)
                        }
                    })
                    //[...new Set( crossIJ )]
                    //crossIJ.filter((x, i) => {return crossIJ.indexOf(x) === i})
                    // Array.from(new Set( crossIJ ))                
                    // console.log('NEW : ', {proprietairesTemp})
                    proprietaires = proprietairesTemp.map((p) => { return <p style={{ borderRadius: '5px', padding: '0px 5px', backgroundColor: proprio === p.proprietaire._id ? '#FFF' : '' }}>{`${p.proprietaire.firstname} ${p.proprietaire.lastname} (${crossIJ.filter(e => e.proprietaire._id === p.proprietaire._id).length})${showFull ? ` - ${p.proprietaire.role}` : ''}`}</p> }) // 
                }

                if (crossIJ.length > 0) {

                    if (showColorsMean) {
                        color = determineColor(mean)
                    }
                    if (showColorsHigher) {
                        color = determineColor(higher)
                    }


                    array.push(<div style={{ textAlign: 'center', fontSize: 10, padding: 3, borderRadius: 7, backgroundColor: color, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gridRow: i + 1, gridColumn: j + 1 }}>
                        <p style={{ fontWeight: 'bolder', margin: 0 }}>{crossIJ.length < 2 ? crossIJ.length + ' risque' : crossIJ.length + ' risques'}</p>
                        {(showVuln || showVulnTop) &&
                            <>
                                <p style={{ fontWeight: 'bolder', margin: 0 }}>---</p>
                                <p style={{ fontWeight: 'bolder', margin: 0 }}>Vulnérabilité :</p>
                            </>
                        }
                        {showVuln && <p style={{ padding: '0px 5px', backgroundColor: '#EEE', borderRadius: 5, fontWeight: '', margin: '3px 0px' }}>Moyenne : <span style={{ color: mean > 3 ? '#F73305' : mean < -3 ? '#0A61E6' : '#222' }}>{mean}</span></p>}
                        {showVulnTop && <p style={{ padding: '0px 5px', backgroundColor: '#EEE', borderRadius: 5, fontWeight: '', margin: '3px 0px' }}>Maximale : <span style={{ color: higher > 3 ? '#F73305' : higher < -3 ? '#0A61E6' : '#222' }}>{higher}</span></p>}
                        {showStates &&
                            <>
                                <p style={{ fontWeight: 'bolder', margin: 0 }}>---</p>
                                <p style={{ fontWeight: 'bolder', margin: 0 }}>{crossIJ.length > 1 ? 'Risques :' : 'Risque :'}</p>
                                {crossIJ.map((el) => {
                                    return <li style={{ cursor: 'pointer', textAlign: 'start' }} onClick={()=>{ toggleNum(0); setStateSelected(el) }} >{el.intitule}</li>
                                })
                                }
                            </>
                        }
                        {showProp &&
                            <>
                                <p style={{ fontWeight: 'bolder', margin: 0 }}>---</p>
                                <p style={{ marginTop: 3 }}><span style={{ fontWeight: 'bolder' }}>{proprietaires.length < 2 ? 'Responsable : ' : 'Responsables : '}</span>{proprietaires.map((p) => { return p })}</p>
                            </>
                        }
                    </div>)
                }
                // else if(i===1 && j===1){                    
                //     array.push(<div style={{backgroundColor: '#FFF'}}>{i}{j}</div>)                    
                // } else {
                //     array.push(<div style={{backgroundColor: '#FEF'}}>{i}{j}</div>)
                // }

                //CHECK SI TRAITEMENT FAIT POUR DERNIERE CASE (AVEC I ET J) ET COLORER COMME IL SE DOIT - DONE
                // EQUILIBRER TAILLE DES LIGNES (PAS QUE LES 3+) - DONE
                // PLUS PAR PROPRIETAIRE
                // PLUS AVEC COULEURS DE LA MOYENNE DES CRITICITES OU VULNERABILITES (par tranche 25% - 50% - 75% ...) - DONE
                // OVERFLOW -Y SCROLL WITHOUT SCROLLBAR - DONE
            }
        }
        return array
    }

    return (
        <>
            <div id='Cartographie' className='containersuivi' style={{ width: '65%', height: '75%', flexDirection: 'row', justifyContent: 'center', padding: 10, overflow: 'hidden' }}>

                <div id='content' style={{ width: '100%', height: '100%', flexDirection: 'row', justifyContent: 'center', overflowY: 'scroll' }}>


                    {listByProcesses.length > 0 && listByRisks.length > 0 &&
                        <>
                            <div id='board' style={{
                                width: '100%', height: '100%', justifyContent: 'center', display: 'grid', gridGap: '0.5rem',
                                // gridAutoRows: `100/${listByRisks.length}%`,
                                // gridAutoFlow: 'row'
                                // borderTop: '2px solid #222',
                                // borderLeft: '2px solid #222',
                                gridTemplateColumns: `repeat(${sens ? listByProcesses.length + 1 : listByRisks.length + 1}, 1fr)`,
                                gridTemplateRows: `repeat(${sens ? listByRisks.length + 1 : listByProcesses.length + 1}, auto)`
                            }}>

                                {/* fond */}
                                {classicMode ?
                                    sens ? fond(listByProcesses.length, listByRisks.length) : fond(listByRisks.length, listByProcesses.length)
                                    :
                                    sens ? fond(listProcesses.length, listByRisks.length) : fond(listByRisks.length, listProcesses.length)
                                }

                                {/* En têtes colonnes */}
                                {sens ?
                                    classicMode ?
                                        columnsHeader(listByProcesses.length)
                                        :
                                        columnsHeader(listProcesses.length)
                                    :
                                    columnsHeader(listByRisks.length)}

                                {/* En têtes lignes */}
                                {sens ?
                                    rowsHeader(listByRisks.length)
                                    :
                                    classicMode ?
                                        rowsHeader(listByProcesses.length)
                                        :
                                        rowsHeader(listProcesses.length)
                                }

                                {/* Cellules */}
                                {classicMode ?
                                    sens ? determinationCellules(listByRisks.length, listByProcesses.length) : determinationCellules(listByProcesses.length, listByRisks.length)
                                    :
                                    sens ? determinationCellules(listByRisks.length, listProcesses.length) : determinationCellules(listProcesses.length, listByRisks.length)
                                }
                            </div>
                        </>
                    }

                </div>

                {showProp &&
                    <div id='proprios' style={{ backgroundColor: '#FFF', borderRadius: 10, width: '12%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '3%' }}>
                        <p style={{ fontWeight: 'bolder', margin: 5 }}>Propriétaires</p>
                        {clientUsers.filter((e) => { return e._id !== "62699350bf7c7ded37e4dfdb" }).map((el) => {
                            return <span onClick={() => { if (proprio === el._id) { setProprio('') } else { setProprio(el._id) } }} style={{ cursor: 'pointer', width: '97%', margin: 2, textAlign: 'start', fontWeight: proprio === el._id ? 'bolder' : '', backgroundColor: proprio === el._id ? '#DDD' : '#EEE', borderRadius: 5, padding: '3px' }}>{el.firstname} {el.lastname}</span>
                        })
                        }
                    </div>
                }
                {(showColorsHigher || showColorsMean) &&
                    <div id='legende' style={{ padding: 5, backgroundColor: '#FFF', borderRadius: 10, width: 'auto', height: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '1.5%' }}>
                        <p style={{ fontWeight: 'bolder', margin: 5 }}>Légende</p>
                        {
                            [
                                { score: ' < -7', color: '#0A61E6' },
                                { score: ' < -4', color: '#8ED8F2' },
                                { score: ' < -1', color: '#DAF0F6' },
                                { score: ' < 1', color: '#51C216' },
                                { score: ' < 4', color: '#FAD02B' },
                                { score: ' < 7', color: '#F48C06' },
                                { score: ' > 7', color: '#F73305' },
                            ].map((el) => {
                                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span style={{ marginRight: 5}}>|</span>
                                    <div style={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: el.color }}></div>
                                    <span style={{ width: '97%', margin: 2, textAlign: 'start', borderRadius: 5, padding: '3px' }}>{(showColorsHigher ? 'Maximal' : 'Moyenne')+el.score}</span>
                                </div>
                            })
                        }
                    </div>
                }

                <div id='filters' style={{ backgroundColor: '#FFF', borderRadius: 10, width: 'auto', height: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '5%' }}>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: classicMode ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: classicMode ? '#222' : '#CCC' }} onClick={() => { setClassicMode(!classicMode) }}>{classicMode ? 'Par process' : 'Par famille'}</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showProp ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showProp ? '#222' : '#CCC' }} onClick={() => { setShowProp(!showProp) }}>{showProp ? '' : ''} Propriétaire</span>
                    {showProp &&
                        <>
                            <span style={{ color: '#DDD' }}>⏵</span>
                            <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showFull ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showFull ? '#222' : '#CCC' }} onClick={() => { setShowFull(!showFull) }}>{showFull ? '' : ''} Fonction</span>
                        </>
                    }
                    <span style={{ color: '#DDD' }}>|</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showStates ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showStates ? '#222' : '#CCC' }} onClick={() => { setShowStates(!showStates) }}>{showStates ? '' : ''} Risques</span>
                    <span style={{ color: '#DDD' }}>|</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showVuln ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showVuln ? '#222' : '#CCC' }} onClick={() => { setShowVuln(!showVuln) }}>{showVuln ? '' : ''} Vulnérabilité moyenne</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showVulnTop ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showVulnTop ? '#222' : '#CCC' }} onClick={() => { setShowVulnTop(!showVulnTop) }}>{showVulnTop ? '' : ''} Vulnérabilité maximale</span>
                    <span style={{ color: '#DDD' }}>|</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showColorsMean ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showColorsMean ? '#222' : '#CCC' }} onClick={() => { setShowColorsMean(!showColorsMean); if (showColorsHigher) { setShowColorsHigher(!showColorsHigher) }; /*if(showColorsLower) {setShowColorsLower(!showColorsLower)}*/ }}>{showColorsMean ? '' : ''} Coloration moyenne</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showColorsHigher ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showColorsHigher ? '#222' : '#CCC' }} onClick={() => { setShowColorsHigher(!showColorsHigher); if (showColorsMean) { setShowColorsMean(!showColorsMean) }; /*if(showColorsLower) {setShowColorsLower(!showColorsLower)}*/ }}>{showColorsHigher ? '' : ''} Coloration critique </span>
                    <span style={{ color: '#DDD' }}>|</span>
                    <span style={{ height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: sens ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: sens ? '#222' : '#CCC' }} onClick={() => { setSens(!sens) }}>Inverser</span>
                    {/* <span style={{height: '60%', lineHeight: '100%', width: 'auto', maxWidth: '40%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: showColorsLower ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: showColorsLower ? '#222' : '#CCC'}} onClick={()=>{ setShowColorsLower(!showColorsLower); if(showColorsMean) {setShowColorsMean(!showColorsMean)}; if(showColorsHigher) {setShowColorsHigher(!showColorsHigher)} } }>{showColorsHigher ? '' : ''} Coloration réallocation </span> */}

                    {/* const [classicMode, setClassicMode] = useState(false) //on SUpport, Realisation and Governance or on processes themselves
    const [showProp, setShowProp] = useState(true) // show proprietaire or not
    const [showFull, setShowFull] = useState(true) // show function of proprietaire or not
    const [showVuln, setShowVuln] = useState(true) // show vulnerability or not
    const [showColorsMean, setShowColorsMean] = useState(true) // show alternative colors (heatmap) bu mean
    const [showColorsHigher, setShowColorsHigher] = useState(true) // show alternative colors (heatmap) by highest */}
                </div>
            </div>
        </>
    )
}
export default Carto;