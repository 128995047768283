import React, { useState, useEffect } from 'react'
import '../public/styles/informations.css'

//moment
import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    Input,
    Select,
    Badge,
    Card,
    Slider,
    Statistic,
    Row,
    Col
} from 'antd';

import {
    LeftOutlined,
    RightOutlined,
    SwapOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined
} from '@ant-design/icons';

import Score from './Score';
import AvatarUpload from './AvatarUpload';

//HELPERS
import { formatCodeNew } from "../helpers"
import { short } from "../helpers"
import { scoreTotal } from "../helpers"

moment.locale('fr')



const { TextArea } = Input;
const { Option } = Select

const Informations = ({ user, tempState, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected }) => {

    // useEffect(() => { console.log({ clientSelected }) }, [clientSelected])
    // useEffect(() => { console.log({ stateSelected }) }, [stateSelected])

    const [crossing, setCrossing] = useState([])

    //fetch crossing
    useEffect(() => {

        let processesCrossing = [];
        if (clientSelected && stateSelected) {
            listFollowed.forEach((state) => {
                if (state.risk.family._id === stateSelected.risk.family._id) { processesCrossing.push(state.process.libelle) }
            })
        }
        processesCrossing = [... new Set(processesCrossing)]
        //console.log(processesCrossing)
        setCrossing(processesCrossing)

    }, [listFollowed, stateSelected])

    //LISTENERS CHECK
    // useEffect(()=>{if (user) {console.log('user in Informations : ', user)}}, [user])
    // useEffect(()=>{if (clientSelected) {console.log('clientSelected in Informations : ', clientSelected)}}, [clientSelected])
    // useEffect(()=>{if (listFollowed) {console.log('listFollowed in Informations : ', listFollowed)}}, [listFollowed])
    // useEffect(()=>{if (listByRisks) {console.log('listByRisks in Informations : ', listByRisks)}}, [listByRisks])
    // useEffect(()=>{if (listByProcesses) {console.log('listByProcesses in Informations : ', listByProcesses)}}, [listByProcesses])
    // useEffect(()=>{if (stateSelected) {
    //     console.log('stateSelected in Informations : ', stateSelected);
    // }}, [stateSelected])

    return (
        <>

            <div className='containersuivi'>
                {!stateSelected &&
                    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>Sélectionnez un risque pour l'afficher</h1>
                    </div>
                }
                {stateSelected &&
                    <>
                        {clientSelected?.methodo_le_ray && clientSelected?.score && stateSelected?.calcul &&
                            <>
                                <div title={`Score`} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', top: '17%', left: '19%', width: 50, height: 50, borderRadius: 3, color: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FFF' : '#222', backgroundColor: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 500 ? '#7E005D' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 50 ? '#C80094' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FF0DC0' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 2 ? '#FF9BE5' : '#FFD9F5', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 'bold' }}>
                                    {scoreTotal(clientSelected?.score, stateSelected?.calcul)}
                                </div>
                                {stateSelected?.calcul?.confiance && <span style={{ zIndex: 1, position: 'absolute', top: '21.75%', left: '19.5%', fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: '#CCC', color: '#222', borderRadius: '50%' }}>{stateSelected?.calcul?.confiance}</span>}
                            </>
                        }
                        {/* BANDEAU 1 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <h2 title={stateSelected.intitule} style={{ margin: 0 }}>{!clientSelected?.level_more && formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>
                            {stateSelected.replaced_by &&
                                <span>{`Modifié le ${moment(tempState ? stateSelected.date_creation : stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by.firstname} ${stateSelected.replaced_by.lastname}`}<img className='avatar-right' src={stateSelected.replaced_by.avatar} /></span>
                            }
                            {stateSelected.priority &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '7%', padding: 3, borderRadius: 15, backgroundColor: 'pink', border: '2px solid red', position: 'absolute', top: '18%', right: '19%' }}>
                                    <span style={{ fontWeight: 'bold' }}> Risque prioritaire </span>
                                </div>
                            }
                        </div>

                        {/* BANDEAU 2 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <div><span>{`Propriétaire : ${stateSelected.proprietaire?.habilitations?.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected.proprietaire?.firstname} ${stateSelected.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected.proprietaire?.avatar} /></div>
                            <div><span>{`Risque créé le ${moment(stateSelected.date_creation).format('LL')} par ${stateSelected.creator?.firstname} ${stateSelected.creator?.lastname}`}</span><img className='avatar-right' src={stateSelected?.creator?.avatar} /></div>

                        </div>

                        {/* DEUX COLONNES EN DESSOUS */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', paddingTop: 15, height: '85%', width: '90%' }}>

                            {/* COLONNE DE GAUCHE */}
                            <div className='subcontainersuivi' style={{ height: '100%', justifyContent: 'space-between' }}>

                                <div style={{ height: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <span style={{ padding: '5px 10px' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Famille : </span>{stateSelected.risk?.family.libelle} · {stateSelected.risk?.libelle}</span>
                                    <span style={{ padding: '5px 10px' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Processus métier : </span>{stateSelected.process?.process_family} · {stateSelected.process?.libelle}</span>
                                    {clientSelected.objectif_activated && <span style={{ padding: '5px 10px' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Objectif COSO : </span>{stateSelected.objectif}</span>}
                                    {stateSelected?.process.process_family === 'Réalisation' &&
                                        <>
                                            {clientSelected.domaines_activated && <span style={{ padding: '5px 10px' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Domaines de compétence : </span>{stateSelected?.domaines_competence.length > 0 ? stateSelected?.domaines_competence.map(e => e) : `Aucun domaine de compétence`}</span>}
                                            {clientSelected.modes_activated && <span style={{ padding: '5px 10px', marginBottom: 10 }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Modes d'intervention : </span>{stateSelected?.modes_intervention.length > 0 ? stateSelected?.modes_intervention.map(e => e) : `Aucun mode d'intervention`}</span>}
                                        </>
                                    }
                                    <span style={{ padding: 10, backgroundColor: '#FFFEEE', borderRadius: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>{`La famille de risques "${stateSelected.risk?.family.libelle}" concerne les processus métiers : ${crossing.map((e, i) => String(' ' + e + `${i >= crossing.length - 1 ? '.' : ''}`))}`}</span>
                                </div>

                                <div className='field' style={{ height: '40%', maxHeight: '40%' }}>
                                    <span className='title-field'>Evènement(s) associé(s) au risque</span>
                                    <p className='content'>{stateSelected.event}</p>
                                </div>

                            </div>

                            {/* COLONNE DE DROITE */}
                            <div className='subcontainersuivi' style={{ height: '100%', justifyContent: 'space-between' }}>

                                <div className='field' style={{ height: '30%', maxHeight: '30%' }}>
                                    <span className='title-field'>Causes plausibles du risque</span>
                                    <p className='content'>{stateSelected?.causes}</p>
                                </div>

                                <div className='field' style={{ height: '30%', maxHeight: '30%' }}>
                                    <span className='title-field'>Conséquences potentielles de la réalisation du risque</span>
                                    <p className='content'>{stateSelected?.consequences}</p>
                                </div>

                                <div className='field' style={{ height: '30%', maxHeight: '30%' }}>
                                    <span className='title-field'>Indicateurs d'observation du risque</span>
                                    <p className='content'>{stateSelected?.indicateurs}</p>
                                </div>

                            </div>

                        </div>
                        {/* PROCESS ET FAMILLE */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', marginBottom: 10 }}>

                        </div>

                    </>
                }
            </div>

        </>
    )
}
export default Informations;