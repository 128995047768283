import React from 'react'
import '../public/styles/liste.css'

//antd
import {
    CloseOutlined,
} from '@ant-design/icons';

import "antd/dist/antd.css";

const ListeDocs = ({slotToSave, screen, data, toggleSelection, userSelected, deletePiece, deletePieceAudit}) => {
    // console.log({data})
    return (
        <>
            <div className='listdocs' style={{width: '90%', flexWrap: 'wrap'}}>
                <div className='titledocs' style={{marginBottom: 20, width: '100%', fontWeight: 'bolder', textAlign: 'center'}}>Pièces jointes {screen==='informations' ? 'de suivi global' : screen==='incidents' ? `de l'incident` : screen==='actions' ? `du plan d'action` : slotToSave === 'audit' ? `de l'audit` : slotToSave === 'reco' ? 'du risque audité' : ''}</div>
                <div className='listdocs' style={{width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'justify'}}>
                    {data && data.length > 0 &&
                    data.map((el, i)=>{return <div key={i} style={{width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><a name={el.name} className='url' href={el.url} target="_blank">· {el.name}</a><CloseOutlined style={{fontSize: 10}} onClick={()=>{slotToSave === 'audit' || slotToSave === 'reco' ? deletePieceAudit(el.name) : deletePiece(el.name)}}/></div>})
                    }
                </div>
            </div>            
        </>
    )
    }
    export default ListeDocs;