import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import '../public/styles/create.css'

import { Link } from 'react-router-dom';

//moment
import moment from "moment/min/moment-with-locales"

//antd
import {
    CalculatorOutlined,
    CloseOutlined,
    SlidersOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';

//REDUX
import { useDispatch, useSelector } from 'react-redux'

//ant_____________________
import {
    message,
    Input,
    Select,
    Form,
    TreeSelect,
    Steps,
    Divider,
    InputNumber
} from 'antd';

import {
    PlusCircleOutlined,
    EyeOutlined,
    FlagFilled
} from '@ant-design/icons';
import Button from './Button';
import AddItem from './AddItem';
import ScoreDots from './ScoreDots';
import ScoreDotsParametrable from './ScoreDotsParametrable';
import ScoreRapportDots from './ScoreRapportDots';
import ScoreSliderParametrable from './ScoreSliderParametrable'
import ScoreSliderRapportParametrable from './ScoreSliderRapportParametrable'
import { formatCodeNew, short, shortparam, compareObjects, criticity, vulnerability, predictMaitriseCible, rapportGC, calculNet, calculNetSmooth, scoreTotal } from "../helpers"
import '../public/styles/audits.css'

const { Option } = Select

const Audits = ({ toggleNum, stateId, setStateId, statesWithReco, setStatesWithReco, audits, setAudits, auditSelected, setAuditSelected, clientUsers, stateSelected, setStateSelected, user, clientSelected, listFollowed, listByRisks, listByProcesses }) => {

    // useEffect(() => { console.log({ audits }) }, [audits])

    const dispatch = useDispatch()

    const [hover, setHover] = useState('')

    const [zoom, setZoom] = useState(1)

    const handleCloture = async () => {

        const data = await fetch(`${process.env.REACT_APP_BACK_END}/audits/cloture/${auditSelected._id}`)
        const json = await data.json()

        if (json.result) {
            setAuditSelected(json.auditUpdated)
        } else {
            console.log(json.error)
        }
    }

  
    // DONE bouton créer la reco d'un risque (check une seule par risque)
    // DONE finir le create/reco que la reco soit bien enregistrée en base...

    // DONE Modifier un audit 
    // DONE bouton modifier la reco d'un risque

    // DONE Mettre un verrou au moment de la création de l'audit : si au moins 1 risque déjà en cours d'audit, impossible (1 risque, 1 audit)
    // NON PRIO Habiliation audit bien bordée (filtre obligé sur les states de l'audit (listFollowed filtrée d'emblée sur risques rattachés à mes audits chez ce client ?) ?)

    // DONE Clôture de l'audit

    // DONE liens vers les risques concernés //onClick={()=>{ toggleNum(0); setStateSelected(el) }}

    // DONE pièces à joindre audit et reco

    // DONE Questions et précisions : 1 seul audit en cours ou plusieurs ? 1 seule reco par risque. 1 seul auditeur référent sur la plateforme sur un audit, sort les pièces si besoin.


    return (
        <>
            <div className='containersuivi' id='content' style={{ padding: '50px 100px', justifyContent: 'flex-start', alignItems: 'flex-start', overflowY: 'auto' }}>

                <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start', width: '54%', top: '15%', }}>
                    <h3 style={{ color: '#BBB', marginRight: 10 }}>{zoom === 1 ? 'Audits' : zoom === 2 ? 'Audits > Audit' : zoom === 3 ? 'Audits > Audit > Analyse >' : ''}</h3>
                    {zoom === 3 && <Select placeholder={`${listFollowed?.find(e => e._id === stateId)?.intitule}`} style={{ width: '30%', marginBottom: 30 }}
                        onChange={(value) => {
                            setStateId(value)
                        }
                        }>
                        {
                            auditSelected?.audited_risks?.filter(e => { return auditSelected?.reco?.some(f => f.state_concerne === e) })?.map((el, i) => { return <Option key={i} id={i} value={el}>{listFollowed?.filter(e => { return auditSelected?.reco?.find(f => f.state_concerne === e._id) })?.find(e => e._id === el)?.intitule}</Option> })
                        }
                    </Select>
                    }
                </div>

                {zoom === 1 && /// VUE GLOBALE AUDIT
                    <>
                        <h1>Liste des audits</h1>

                        {audits.length > 0 ?
                            [...audits].filter(e => {
                                if (clientSelected?.type_hab === 'Audit') { // FILTRAGE DES AUDITS PAR AUDITEUR (CHACUN NE PEUT VOIR LES AUTRES AUDITS)
                                    return e.chief_audit === user._id;
                                } else if (clientSelected?.type_hab === 'Risk management') {
                                    return true
                                }
                                return true; // Si clientSelected.type_hab n'est pas défini ou évalué à false, ne pas filtrer
                            }).reverse().map((el) => {
                                const listAudits = el?.audited_risks?.map((e, i) => {
                                    // console.log('*-*-*-* ', el?.reco.find(g => g.state_concerne === e)?.maitrise_constat)
                                    return <div style={{ height: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'self-end' }}>
                                            <li style={{ fontSize: 20 }}>{formatCodeNew(listFollowed.find(f => f._id === e)?.process?.libelle, listFollowed.find(f => f._id === e)?.risk?.family?.libelle, listFollowed.find(f => f._id === e)?.risk.code)} · {shortparam(listFollowed.find(f => f._id === e)?.intitule, 44)}</li>
                                            <div title='Voir le risque' onClick={() => { toggleNum(0); setStateSelected(listFollowed.find(f => f._id === e)) }} style={{ marginLeft: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', cursor: 'pointer', backgroundColor: '', borderRadius: '50%', width: 30, height: 30 }}><EyeOutlined style={{ fontSize: 18 }} /></div>
                                        </div>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ backgroundColor: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}>{el?.reco.find(g => g.state_concerne === e)?.maitrise_declar}</div>
                                            <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                            <div style={{ backgroundColor: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}>{el?.reco.find(g => g.state_concerne === e)?.maitrise_constat}</div>
                                            <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                            <div style={{ backgroundColor: el?.reco.find(g => g.state_concerne === e)?.phase_test ? el?.reco.find(g => g.state_concerne === e)?.maitrise_finale ? 'green' : 'orange' : el?.reco.find(g => g.state_concerne === e)?.maitrise_declar ? '#CCC' : '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}></div>
                                            <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                            <div style={{ backgroundColor: el?.reco.find(g => g.state_concerne === e)?.date_cloture_reco ? 'green' : el?.reco.find(g => g.state_concerne === e)?.maitrise_declar ? 'orange' : '#FFF', color: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}>{el?.reco.find(g => g.state_concerne === e)?.phase_test ? el?.reco.find(g => g.state_concerne === e)?.maitrise_finale : el?.reco.find(g => g.state_concerne === e)?.maitrise_constat}</div>
                                        </div>
                                    </div>
                                })
                                return (
                                    <div
                                        onClick={() => { setAuditSelected(el); dispatch({ type: 'selectAudit', audit: el }); setZoom(zoom + 1) }}
                                        style={{ cursor: 'pointer', borderRadius: 10, transform: hover === el._id ? 'scale(1.01)' : 'scale(1)', backgroundColor: hover === el._id ? '#DDD' : '#EEE', padding: 20, margin: '20px 0px', boxShadow: '1px 1px 1px #DDD', width: '100%' }}
                                        onMouseEnter={() => { setHover(el._id) }}
                                        onMouseLeave={() => { setHover('') }}
                                    >
                                        <h2>{el?.libelle} - {el?.nature} - {el?.structure} - {clientUsers.find(e => e._id === el?.chief_audit)?.firstname} {clientUsers.find(e => e._id === el?.chief_audit)?.lastname}</h2>

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                            <h3>Démarrage : {moment(el?.date_demarrage).format('L')} - Rapport final : {el?.date_remise ? moment(el?.date_remise).format('L') : 'Non'} - Clôture : {el?.date_cloture ? moment(el?.date_cloture).format('L') : 'Non'}</h3>
                                            <div style={{ position: '', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', right: '2%', top: '23%' }}>
                                                <div style={{ fontWeight: 'lighter', fontSize: 10, backgroundColor: '', textAlign: 'center', borderRadius: '50%', margin: 0, width: 22, height: 22 }}>Déclaré</div>
                                                <span style={{ opacity: 0, color: "#CCC", position: 'relative', top: '-7px' }} >________</span>
                                                <div style={{ fontWeight: 'lighter', fontSize: 10, backgroundColor: '', textAlign: 'center', borderRadius: '50%', margin: 0, width: 22, height: 22 }}>Constaté</div>
                                                <span style={{ opacity: 0, color: "#CCC", position: 'relative', top: '-7px' }} >___________</span>
                                                <div style={{ fontWeight: 'lighter', fontSize: 10, backgroundColor: '', textAlign: 'center', borderRadius: '50%', margin: 0, width: 22, height: 22 }}>Test</div>
                                                <span style={{ opacity: 0, color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                                <div style={{ fontWeight: 'lighter', fontSize: 10, backgroundColor: '', textAlign: 'center', borderRadius: '50%', margin: 0, width: 22, height: 22 }}>Final</div>
                                            </div>
                                        </div>

                                        {listAudits}
                                    </div>
                                )
                            })
                            :
                            <h3>{`Aucun audit en cours ou passé. Pour structurer votre stratégie d'audit, appuyez-vous sur les outils décisionnels mis à votre disposition.
                            Les graphiques et la cartographie fournissent des représentations visuelles facilitant les arbitrages et les prises de position stratégiques.`}</h3>
                        }
                        {(clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Audit') &&
                            <div style={{ width: '10%', position: 'absolute', left: '45%', bottom: '7%' }}><Link className='link' to={`/create/audit`}> Débuter un audit </Link></div>
                        }
                    </>
                }

                {zoom === 2 && /// DETAILS DE L'AUDIT
                    <>
                        <span style={{ transform: 'rotate(180deg)', position: 'absolute', cursor: 'pointer', top: '20%', left: '20.5%' }} onClick={() => { setAuditSelected({}); dispatch({ type: 'selectAudit', audit: {} }); setZoom(zoom - 1) }}>{'➤'}</span>

                        <div style={{ backgroundColor: '#FFFEEE', width: '100%', padding: 10, borderRadius: 10, marginBottom: 20 }}>
                            <h2>{auditSelected?.libelle} - {auditSelected?.nature} - {auditSelected?.structure} - {clientUsers.find(e => e._id === auditSelected?.chief_audit)?.firstname} {clientUsers.find(e => e._id === auditSelected?.chief_audit)?.lastname}</h2>
                            <h3>Démarrage : {moment(auditSelected?.date_demarrage).format('L')} - Clôture : {auditSelected?.date_cloture ? moment(auditSelected?.date_cloture).format('L') : 'Non'}</h3>
                            <h3>Rapport intermédiaire : {auditSelected?.date_remise_rapport_intermediaire ? moment(auditSelected?.date_remise_rapport_intermediaire).format('L') : 'Non'} - Rapport final : {auditSelected?.date_remise ? moment(auditSelected?.date_remise).format('L') : 'Non'} - Validation des conclusions : {auditSelected?.date_validation_conclusions_finales ? moment(auditSelected?.date_validation_conclusions_finales).format('L') : 'Non'}</h3>
                        </div>

                        <div className='field' style={{ height: '40%', minHeight: '28%', marginBottom: 5 }}>
                            <span className='title-field'>Analyses menées</span>
                            <p className='content'>{auditSelected?.verifications_operated}</p>
                        </div>

                        <div className='field' style={{ height: '40%', minHeight: '28%' }}>
                            <span className='title-field'>Conclusions</span>
                            <p className='content'>{auditSelected?.conclusion}</p>
                        </div>

                        <div style={{ width: '100%', padding: 10 }}>
                            <h2 style={{ color: '#EDB30D', fontWeight: 'bold', marginTop: 10 }}>Détail par risque :</h2>
                            {auditSelected?.audited_risks?.length > 0 &&
                                auditSelected?.audited_risks?.map((e) => { //
                                    return <div
                                        onClick={() => { setStateId(e); dispatch({ type: 'changeStateId', stateId: e }); setZoom(zoom + 1) }}

                                        style={{ cursor: 'pointer', borderRadius: 10, height: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', transform: hover === e ? 'scale(1.01)' : 'scale(1)', backgroundColor: hover === e ? '#DDD' : '#EEE', padding: 20, margin: '20px 0px', boxShadow: '1px 1px 1px #DDD', width: '100%' }}
                                        onMouseEnter={() => { setHover(e) }}
                                        onMouseLeave={() => { setHover('') }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'self-end' }}>
                                            <li style={{ fontSize: 20 }}>{formatCodeNew(listFollowed.find(f => f._id === e)?.process?.libelle, listFollowed.find(f => f._id === e).risk?.family?.libelle, listFollowed.find(f => f._id === e)?.risk.code)} · {shortparam(listFollowed.find(f => f._id === e).intitule, 45)}</li>
                                            <div title='Voir le risque' onClick={() => { toggleNum(0); setStateSelected(listFollowed.find(f => f._id === e)) }} style={{ marginLeft: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', cursor: 'pointer', backgroundColor: '', borderRadius: '50%', width: 30, height: 30 }}><EyeOutlined style={{ fontSize: 18 }} /></div>
                                        </div>

                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <div style={{ backgroundColor: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}>{statesWithReco?.find(state => state._id === e)?.reco?.maitrise_declar}</div>
                                            <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                            <div style={{ backgroundColor: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}>{statesWithReco?.find(state => state._id === e)?.reco?.maitrise_constat}</div>
                                            <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                            <div style={{ backgroundColor: statesWithReco?.find(state => state._id === e)?.reco?.phase_test ? statesWithReco?.find(state => state._id === e)?.reco?.maitrise_finale ? 'green' : 'orange' : statesWithReco?.find(state => state._id === e)?.reco?.maitrise_declar ? '#CCC' : '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}></div>
                                            <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >_________</span>
                                            <div style={{ backgroundColor: statesWithReco?.find(state => state._id === e)?.reco?.date_cloture_reco ? 'green' : statesWithReco?.find(state => state._id === e)?.reco?.maitrise_declar ? 'orange' : '#FFF', color: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 22, height: 22 }}>{statesWithReco?.find(state => state._id === e)?.reco?.phase_test ? statesWithReco?.find(state => state._id === e)?.reco?.maitrise_finale : statesWithReco?.find(state => state._id === e)?.reco?.maitrise_constat}</div>

                                        </div>
                                    </div>
                                })
                            }
                        </div>

                        {(clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Audit') &&
                            <div style={{ width: '20%', position: 'absolute', left: '40%', bottom: '7%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <div className='link' style={{ width: '50%' }} onClick={() => { handleCloture() }} >Clôturer</div>
                                {Object.keys(auditSelected)?.length > 0 && <Link className='link' to={`/create/audit`}> Modifier l'audit </Link>}
                            </div>
                        }
                    </>
                }

                {zoom === 3 && /// VUE PAR RISQUE AUDITE
                    <>
                        <span style={{ transform: 'rotate(180deg)', position: 'absolute', cursor: 'pointer', top: '20%', left: '20.5%' }} onClick={() => { setZoom(zoom - 1); setStateId('') }}>{'➤'}</span>

                        <div style={{ backgroundColor: '#FFFEEE', width: '100%', padding: 10, borderRadius: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h2>{statesWithReco?.find(e => e._id === stateId)?.intitule}</h2>
                                <div title='Voir le risque' onClick={() => { toggleNum(0); setStateSelected(listFollowed.find(f => f._id === stateId)) }} style={{ marginLeft: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', cursor: 'pointer', backgroundColor: '', borderRadius: '50%', width: 30, height: 30 }}><EyeOutlined style={{ fontSize: 18 }} /></div>
                            </div>
                            <h3>Objet : {statesWithReco?.find(e => e._id === stateId)?.reco?.objet ? statesWithReco?.find(e => e._id === stateId)?.reco?.objet : ''}</h3>
                            <h3>Date de création : {moment(statesWithReco?.find(e => e._id === stateId)?.reco?.date_creation_reco).format('L')} {statesWithReco?.find(e => e._id === stateId)?.reco?.date_cloture_reco ? '- Date de clôture : ' + moment(statesWithReco?.find(e => e._id === stateId)?.reco?.date_cloture_reco).format('L') : ''}</h3>
                        </div>

                        <div style={{ padding: '0px 10px', margin: '20px 0px', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>Phase de test : <div style={{ marginLeft: 5, width: 20, height: 20, borderRadius: '50%', backgroundColor: statesWithReco?.find(e => e._id === stateId)?.reco?.phase_test ? 'green' : '#CCC' }}></div></span>
                            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>Analyses sur pièces : <div style={{ marginLeft: 5, width: 20, height: 20, borderRadius: '50%', backgroundColor: statesWithReco?.find(e => e._id === stateId)?.reco?.constat_sur_pieces ? 'green' : statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_declar ? 'red' : '#CCC' }}></div></span>
                            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>Analyses sur place : <div style={{ marginLeft: 5, width: 20, height: 20, borderRadius: '50%', backgroundColor: statesWithReco?.find(e => e._id === stateId)?.reco?.constat_sur_place ? 'green' : statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_declar ? 'red' : '#CCC' }}></div></span>
                            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>Action nécessaire : <div style={{ marginLeft: 5, width: 20, height: 20, borderRadius: '50%', backgroundColor: statesWithReco?.find(e => e._id === stateId)?.reco?.plan_action_necessaire ? 'red' : '#CCC' }}></div></span>
                        </div>

                        <div style={{ padding: '0px 10px', textAlign: 'left', }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Propriétaire du risque : </span>{statesWithReco?.find(e => e._id === stateId)?.reco?.proprietaire ? `${clientUsers?.find(e=>e?._id === statesWithReco?.find(e => e._id === stateId)?.reco?.proprietaire)?.firstname} ${clientUsers?.find(e=>e?._id === statesWithReco?.find(e => e._id === stateId)?.reco?.proprietaire)?.lastname} - ${clientUsers?.find(e=>e?._id === statesWithReco?.find(e => e._id === stateId)?.reco?.proprietaire)?.role}` : 'Aucun'}<img className='avatar-right' src={clientUsers?.find(e=>e?._id === statesWithReco?.find(e => e._id === stateId)?.reco?.proprietaire)?.avatar} /></div>

                        <div style={{ padding: '0px 10px', marginBottom: 15, textAlign: 'left', }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Service(s) associé(s) : </span>{statesWithReco?.find(e => e._id === stateId)?.reco?.services_metiers?.length > 0 ? statesWithReco?.find(e => e._id === stateId)?.reco?.services_metiers : 'Aucun'}</div>

                        <div className='field' style={{ height: '40%', minHeight: '30%', margin: '5px 0px' }}>
                            <span className='title-field'>Analyses menées et conclusions</span>
                            <p className='content'>{statesWithReco?.find(e => e._id === stateId)?.reco?.content}</p>
                        </div>

                        <div className='field' style={{ height: '40%', minHeight: '30%' }}>
                            <span className='title-field'>Recommandations</span>
                            <p className='content'>{statesWithReco?.find(e => e._id === stateId)?.reco?.descr_action}</p>
                        </div>

                        <div style={{ marginTop: 10, borderRadius: 10, width: '100%', backgroundColor: '#FFFEEE', height: '35%', padding: '20px 50px' }}>
                            <span style={{ fontWeight: 'bold', position: 'relative', left: '-5%', top: '-20%' }}>Progression</span>
                            <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                <div style={{ backgroundColor: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', fontSize: 30, margin: 0, width: 50, height: 50 }}>{statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_declar}</div>
                                <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >__________________________________</span>
                                <div style={{ backgroundColor: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', fontSize: 30, margin: 0, width: 50, height: 50 }}>{statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_constat}</div>
                                <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >__________________________________</span>
                                <div style={{ backgroundColor: statesWithReco?.find(e => e._id === stateId)?.reco?.phase_test ? statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_finale ? 'green' : 'orange' : statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_declar ? '#CCC' : '#FFF', fontSize: 30, textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 50, height: 50 }}></div>
                                <span style={{ color: "#CCC", position: 'relative', top: '-7px' }} >__________________________________</span>
                                <div style={{ backgroundColor: statesWithReco?.find(e => e._id === stateId)?.reco?.date_cloture_reco ? 'green' : statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_declar ? 'orange' : '#FFF', fontSize: 30, color: '#FFF', textAlign: 'center', borderRadius: '50%', outline: '1px solid #CCC', margin: 0, width: 50, height: 50 }}>{statesWithReco?.find(e => e._id === stateId)?.reco?.phase_test ? statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_finale : statesWithReco?.find(e => e._id === stateId)?.reco?.maitrise_constat}</div>

                            </div>

                            <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                <div style={{ fontSize: 10, textAlign: 'center', height: 0 }}>{statesWithReco?.find(e => e._id === stateId)?.reco?.date_creation_reco ? moment(statesWithReco?.find(e => e._id === stateId)?.reco?.date_creation_reco).format('L') : ''}</div>
                                <span style={{ color: "#FFF", position: 'relative', top: '-7px' }} >___________________</span>
                                <div style={{ fontSize: 10, textAlign: 'center', height: 0 }}>{statesWithReco?.find(e => e._id === stateId)?.reco?.date_constat ? moment(statesWithReco?.find(e => e._id === stateId)?.reco?.date_constat).format('L') : ''}</div>
                                <span style={{ color: "#FFF", position: 'relative', top: '-7px' }} >___________________</span>
                                <div style={{ fontSize: 10, textAlign: 'center', width: 50, height: 0 }}></div>
                                <span style={{ color: "#FFF", position: 'relative', top: '-7px' }} >___________________</span>
                                <div style={{ fontSize: 10, textAlign: 'center', width: 50, height: 0 }}>{statesWithReco?.find(e => e._id === stateId)?.reco?.date_cloture_reco ? moment(statesWithReco?.find(e => e._id === stateId)?.reco?.date_cloture_reco).format('L') : ''}</div>

                            </div>
                        </div>

                        {(clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Audit') &&
                            <div style={{ width: '10%', position: 'absolute', left: '45%', bottom: '7%' }}><Link className='link' to={`/create/reco`}>{!statesWithReco?.find(e => e._id === stateId)?.reco ? `Déposer l'analyse` : `Amender l'analyse`}</Link></div>
                        }
                    </>
                }
            </div>
        </>
    )
}
export default Audits;