import React, { useState, useEffect, useRef } from 'react'
import Xarrow from "react-xarrows";
import '../public/styles/marcigraph.css'
import { formatCodeNew, short } from "../helpers"
import { CloseOutlined } from '@ant-design/icons';


const MarciGraphR = ({ toggleNum, setStateSelected, clientSelected, dataSet, handleChangeDataSet, sliderM, sliderA, sliderR, listFollowed, familyFilter, processFilter, filters }) => {

    const [hover, setHover] = useState('')

    const [aleaGraphs, setAleaGraphs] = useState({})

    useEffect(()=>{
        let temp = {}
        let aleaA = (Math.round(Math.random()) * 2 - 1)*(Math.random()*3.5);
            temp=({...temp, a: aleaA})
        let aleaB = (Math.round(Math.random()) * 2 - 1)*(Math.random()*3.5);
            temp=({...temp, b: aleaB})
        setAleaGraphs(temp)
    }, [])

//LISTENER dataSet
    //useEffect(()=>{console.log({dataSet})}, [dataSet])

    useEffect(()=>{
        
        const filterData = async () => {

            let temp = [...listFollowed];

        //1 - je vais en base pour les 3 derniers filtres

            // const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/filter`, {
            //     method: 'PUT',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({filters: filters, clientId: clientSelected?._id})
            // })
            // const json = await data.json()

            // if(json.result) {
            //     //console.log({resultatBack: json.statesToSendFront})
            //     temp = json.statesToSendFront
            // }
        

        //1 bis je prends dans listFollowed pour avoir les populates pour la suite

        if ( filters.confiance ) { temp = temp.filter(e=>e.calcul?.confiance === filters.confiance) }

        if ( filters.objectif ) { temp = temp.filter(e=>e.objectif === filters.objectif) }
        
        if ( filters.modes_intervention ) { temp = temp.filter(e=>e.modes_intervention.find(f=>f === filters.modes_intervention)) }

        if ( filters.domaines_competence ) { temp = temp.filter(e=>e.domaines_competence.find(f=>f === filters.domaines_competence)) }

        
        //console.log({temp1 : temp})

        //2 - je me sers de listByProcess et listByFamily pour filtrer les deux premiers filtres

        if ( familyFilter ) { temp = temp.filter(e=>e.risk?.family?.libelle === familyFilter) }

        if ( processFilter ) { temp = temp.filter(e=>e.process?.libelle === processFilter) }

        //console.log({temp2 : temp})

        //3 - Je sort puis slice en fonction des attentes des sliders
        
        temp.sort( (a, b) => { return (a.lvl_impact.net+a.lvl_proba.net-a.lvl_maitrise) - (b.lvl_impact.net+b.lvl_proba.net-b.lvl_maitrise) } )

        if ( sliderM ) {
            temp = temp.filter(f=>f.crit >= 4 && f.lvl_maitrise <=3) 
            temp = temp.reverse().slice(0, sliderM)
        }
        
        if ( sliderA ) {
            temp = temp.filter(f=>f.crit >= 4 && f.lvl_maitrise >=4)
            temp = temp.slice(0, sliderA)
        }
        
        if ( sliderR ) {
            temp = temp.filter(f=>f.crit <= 3 && f.lvl_maitrise >3)
            temp = temp.slice(0, sliderR)
        }

        //console.log({temp3 : temp})

        //4- Je renvois au setter
        handleChangeDataSet(temp) 

        }
        filterData()

    }, [filters, familyFilter, processFilter, sliderM, sliderA, sliderR])

    //function display data for each grid 6x6
    const displayData = (data, crit, maitrise) => {
        return data.map((e, i)=>{
            if(e.crit === crit && e.lvl_maitrise === maitrise) {
                return <div className='pointmm' onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseEnter={()=>{setHover(e._id)}} onMouseLeave={()=>{setHover('')}} crit={e.crit} maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} key={e._id} style={{ position: 'relative', opacity: hover === e._id ? 0.8 : 1, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: 3, padding: '0px 5px 0px 5px', color: '#222'}}>{e.intitule.length > 50 ? e.intitule.slice(0,50)+'...' : e.intitule}</div>
            } 
        })
    }

    const displayDataDots = (data) => {
        return data.map((e, i)=>{
            //console.log(data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id))
        return (
                <div className='pointm' onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseEnter={()=>{setHover(e._id)}} onMouseLeave={()=>{setHover('')}} crit={e.crit} maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} key={e._id} style={{ position: 'absolute', opacity: hover === e._id ? 0.8 : 1, width: 20, height: 20, left: `${e.lvl_maitrise/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24 > 95 ? 95 : e.lvl_maitrise/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24}%`, bottom: `${e.crit/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24 > 95 ? 95 : e.crit/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: '50%', padding: '0px 5px 0px 5px', color: '#222'}}>
                    <div style={{position: 'absolute', right: '25px', top: 2}}>    
                        <span>{formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)}</span>
                    </div>
                </div>
                )
        })
    }
    
    const displayDataDotsBig = (data) => {
        return data.map((e, i)=>{
            //console.log(data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id))
            let listFilteredForPoint = data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise)
            let isTheFirst = listFilteredForPoint.findIndex(g=>g._id === e._id) === 0 ? true : false
            //console.log({listFilterdForPoint: listFilteredForPoint.length, isTheFirst})
        return (
                <>
                {isTheFirst &&
                <div className={hover.length === 1 ? 'pointm' : ''} onClick={()=>{ if(hover.length === 1) {toggleNum(0); setStateSelected(e)} }} crit={e.crit} maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} code={formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)} onMouseEnter={()=>{setHover(listFilteredForPoint)}} key={e._id} style={{  position: 'absolute', opacity: hover === e._id ? 0.8 : 1, width: 20+listFilteredForPoint.length*3, height: 20+listFilteredForPoint.length*3, left: `${e.lvl_maitrise/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1)) > 95 ? 95 : e.lvl_maitrise/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1))}%`, bottom: `${e.crit/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.b*(i%2 === 0 ? -1 : 1)) > 95 ? 95 : e.crit/6*95+data.filter(f=>e.crit === f.crit && e.lvl_maitrise === f.lvl_maitrise).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.b*(i%2 === 0 ? -1 : 1))}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: '50%', padding: '0px 5px 0px 5px', color: '#222', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{ fontSize: 7+listFilteredForPoint.length}}>{listFilteredForPoint.length > 1 ? listFilteredForPoint.length : ''}</span>
                    {hover.length > 1 && hover[0].crit === e.crit && hover[0].lvl_maitrise === e.lvl_maitrise &&
                    <div style={{ zIndex: 50, width: '4.5vw', borderRadius: 5, padding: 7, backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', right: 25+listFilteredForPoint.length*3}}>
                        <CloseOutlined style={{cursor: 'pointer', position: 'absolute', top: `calc('50%-8px')`, left: 3, fontSize: 8, color: '#111'}} onClick={()=>{setHover([])}}/>
                        {hover.sort((a, b)=>{return a.risk.code-b.risk.code}).map((d)=>{
                            // console.log(d)    
                            return <span style={{padding: '0px 3px'}} key={d._id} crit={d.crit} maitrise={d.lvl_maitrise} probabilité={d.lvl_proba.net} impact={d.lvl_impact.net} name={`${d.intitule}`} code={formatCodeNew(d.process.libelle, d.risk?.family.libelle, d.risk.code)} className='pointm' onClick={()=>{ toggleNum(0); setStateSelected(d) }}>{formatCodeNew(d.process.libelle, d.risk?.family.libelle, d.risk.code)}</span>
                        })
                        }
                    </div>
                    }
                </div>
                }
                </>
                )
        })
    }  

return (
    <>
        <div style={{position: 'relative', width:'100%', height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>

            {/* fonds */}
            {/* zone MARCI millieu */}
            <div style={{opacity: 0.9, fontSize: 40, border: '1px dashed #FFF', display: 'flex', position: 'relative', width: '30%', height: '30%', flexWrap: 'wrap'}}>
                <div style={{color: '#FFF', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', height: '50%'}}>M</div>
                <div style={{color: '#FFF', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', height: '50%'}}>A</div>
                <div style={{color: '#FFF', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', height: '50%'}}>CI</div>
                <div style={{color: '#FFF', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', height: '50%'}}>R</div>
            </div>

            {/* fonds */}
            <div className='fond' style={{zIndex: -1}}>

                <div className='onem'></div>
                <div className='twom'></div>
                <div className='threem'></div>
                <div className='fourm'></div>

            </div>

            {/* grid names if < */}
            {dataSet.length < 10 &&
            <div className='board'>

                <div className='cell11'>{displayData(dataSet, 1, 1)}</div><div className='cell12'>{displayData(dataSet, 1, 2)}</div><div className='cell13'>{displayData(dataSet, 1, 3)}</div><div className='cell14'>{displayData(dataSet, 1, 4)}</div><div className='cell15'>{displayData(dataSet, 1, 5)}</div><div className='cell16'>{displayData(dataSet, 1, 6)}</div>
                <div className='cell21'>{displayData(dataSet, 2, 1)}</div><div className='cell22'>{displayData(dataSet, 2, 2)}</div><div className='cell23'>{displayData(dataSet, 2, 3)}</div><div className='cell24'>{displayData(dataSet, 2, 4)}</div><div className='cell25'>{displayData(dataSet, 2, 5)}</div><div className='cell26'>{displayData(dataSet, 2, 6)}</div>
                <div className='cell31'>{displayData(dataSet, 3, 1)}</div><div className='cell32'>{displayData(dataSet, 3, 2)}</div><div className='cell33'>{displayData(dataSet, 3, 3)}</div><div className='cell34'>{displayData(dataSet, 3, 4)}</div><div className='cell35'>{displayData(dataSet, 3, 5)}</div><div className='cell36'>{displayData(dataSet, 3, 6)}</div>
                <div className='cell41'>{displayData(dataSet, 4, 1)}</div><div className='cell42'>{displayData(dataSet, 4, 2)}</div><div className='cell43'>{displayData(dataSet, 4, 3)}</div><div className='cell44'>{displayData(dataSet, 4, 4)}</div><div className='cell45'>{displayData(dataSet, 4, 5)}</div><div className='cell46'>{displayData(dataSet, 4, 6)}</div>
                <div className='cell51'>{displayData(dataSet, 5, 1)}</div><div className='cell52'>{displayData(dataSet, 5, 2)}</div><div className='cell53'>{displayData(dataSet, 5, 3)}</div><div className='cell54'>{displayData(dataSet, 5, 4)}</div><div className='cell55'>{displayData(dataSet, 5, 5)}</div><div className='cell56'>{displayData(dataSet, 5, 6)}</div>
                <div className='cell61'>{displayData(dataSet, 6, 1)}</div><div className='cell62'>{displayData(dataSet, 6, 2)}</div><div className='cell63'>{displayData(dataSet, 6, 3)}</div><div className='cell64'>{displayData(dataSet, 6, 4)}</div><div className='cell65'>{displayData(dataSet, 6, 5)}</div><div className='cell66'>{displayData(dataSet, 6, 6)}</div>

            </div>
            }
            {/* dots if > */}
            {/* {dataSet.length >= 10 && dataSet.length < 30 &&
                <div className='board'>
                    {displayDataDots(dataSet)}
                </div>    
            } */}
            {dataSet.length >= 10 &&
                <div className='board'>
                    {displayDataDotsBig(dataSet)}
                </div>    
            }
            {/* points */}
            {/* <div>

            {dataSet.map((risk, i)=>{   
                return (
                    <>
                    {risk.lvl_proba.brut && risk.lvl_impact.brut &&
                    <>
                        {brut && dataSet.filter(e=>e.lvl_impact.brut === risk.lvl_impact.brut && e.lvl_proba.brut === risk.lvl_proba.brut).length === 1 &&
                        <div id={`${risk.intitule}`+'b'} etat='Brut' maitrise={risk.lvl_maitrise} probabilité={risk.lvl_proba.brut} impact={risk.lvl_impact.brut} name={`${risk.intitule}`+' - Brut'} className='point' style={{fontSize: 10, maxWidth: 80, margin: 2, fontWeight: 'normal', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#FFF', border: '0px solid #DDD',
                        position: 'relative', left: `${risk.lvl_impact.brut/6*96}%`, bottom: `${(risk.lvl_proba.brut/6*95)}%`,
                        borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222'}}>{risk.intitule}</div>
                        }
                        {brut && dataSet.filter(e=>e.lvl_impact.brut === risk.lvl_impact.brut && e.lvl_proba.brut === risk.lvl_proba.brut).length > 1 &&
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative',
                        fontSize: 10, maxWidth: 80, margin: 2, fontWeight: 'normal', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#FFF', border: '0px solid #DDD', borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222',
                        left: `${risk.lvl_impact.brut/6*96}%`, bottom: `${(risk.lvl_proba.brut/6*95)}%`,}}>
                        {dataSet.filter(e=>e.lvl_impact.brut === risk.lvl_impact.brut && e.lvl_proba.brut === risk.lvl_proba.brut).map((e)=>{return <span id={`${e.intitule}`+'b'} etat='Brut' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.brut} impact={e.lvl_impact.brut} name={`${e.intitule}`+' - Brut'} className='point'>· {risk.intitule}</span>})
                        }
                        </div>
                        }
                        {net && dataSet.filter(e=>e.lvl_impact.net === risk.lvl_impact.net && e.lvl_proba.net === risk.lvl_proba.net).length === 1 &&
                        <div id={`${risk.intitule}`+'n'} etat='Net' maitrise={risk.lvl_maitrise} probabilité={risk.lvl_proba.net} impact={risk.lvl_impact.net} name={`${risk.intitule}`+' - Net'} className='point' style={{fontSize: 10, maxWidth: 80, fontWeight: 'bold', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#CCC', border: '0px solid #222',
                        position: 'relative', left: `${risk.lvl_impact.net/6*96}%`, bottom: `${(risk.lvl_proba.net/6*95)}%`,
                        borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222'}}>{risk.intitule}</div>
                        }
                        {net && dataSet.filter(e=>e.lvl_impact.net === risk.lvl_impact.net && e.lvl_proba.net === risk.lvl_proba.net).length > 1 &&
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative',
                        fontSize: 10, maxWidth: 80, margin: 2, fontWeight: 'normal', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#CCC', border: '0px solid #222', borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222',
                        left: `${risk.lvl_impact.net/6*96}%`, bottom: `${(risk.lvl_proba.net/6*95)}%`,}}>
                        {dataSet.filter(e=>e.lvl_impact.net === risk.lvl_impact.net && e.lvl_proba.net === risk.lvl_proba.net).map((e)=>{return <span id={`${e.intitule}`+'b'} etat='Brut' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`+' - Brut'} className='point'>· {risk.intitule}</span>})
                        }
                        </div>
                        }
                        {(risk.lvl_impact.net != risk.lvl_impact.brut || risk.lvl_proba.net != risk.lvl_proba.brut) && (brut && net) &&
                        <Xarrow
                            start={`${risk.intitule}`+'b'}
                            end={`${risk.intitule}`+'n'}
                            color='#111'
                            strokeWidth={1}
                            curveness={0}
                            dashness={true}
                        />                        
                        }
                    </>
                    }
                    </>
                )
            })
            }

            </div> */}


        </div>
    </>
)
}
export default MarciGraphR;